/*--------------------------------------------------------------
# COMMON STYLE START
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
    font-family: 'pixter-granularregular';
    src: url('../fonts/pixter-granular.woff2') format('woff2'),
         url('../fonts/pixter-granular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.pixter-font{
    font-family: 'pixter-granularregular' !important;
}
html {
    scroll-behavior: smooth;
    height: 100%;
}

.body {
    font-family: "Montserrat", sans-serif;
    background-color: #f9f9f9;
}

.body_footer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.loadingOverlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 99999; /* Specify a stack order in case you're using a different order for other elements */
    text-align: center;
    padding-top: 25%;
}

a {
    cursor: pointer;
}

.blueLink {
    color: blue;
}

a,
button {
    transition: all 0.3s ease;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.link {
    text-decoration: none;
}

.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.disable_slider {
    pointer-events: none;
    opacity: 0.5;
}

.title {
    position: relative;
    z-index: 0;
    margin: 0;
    font-weight: 700;
    font-size: 34px;
    line-height: 37px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #4c4c4c;
}

    .title::after {
        content: '';
        position: absolute;
        max-width: 105px;
        width: 100%;
        height: 3px;
        margin: 0 auto;
        top: auto;
        bottom: -31px;
        left: 0;
        right: 0;
        background-color: #ff0d0d;
    }

.main__container {
    max-width: 1420px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}

.dropdown-toggle-none::after {
    display: none;
}

.payment-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.payment-header .payments__form-title{
    margin-bottom: 0;
}
.cancel-btn{
    font-size: 12px;
    color: red;
}
.secure-head{
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 10px;
    align-items: center;
}
.secure-head li p{
    margin-bottom: 0;
}
.secure-head img{
    width: 50px;
    height: 45px;
    object-fit: cover;
}
.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
}

    .dropdown-item:focus .buyer__login-arrow,
    .dropdown-item:hover .buyer__login-arrow,
    .dropdown-item:hover .organiser__login-arrow,
    .dropdown-item:focus .organiser__login-arrow {
        transform: translateX(-10px);
        transition: all 0.3s ease-in-out;
    }

    .dropdown-item:focus .login__title,
    .dropdown-item:hover .login__title {
        color: #ff0000;
        background-color: transparent;
    }

.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 450px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.right .modal-body {
    padding: 15px 15px 80px;
}


/*Right*/
.modal.right .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.show .modal-dialog {
    right: 0;
}

.cookie-consent {
    bottom: 0px;
    height: 50px;
    color: #fff;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    background: #292929;
    z-index: 120;
    cursor: pointer;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cookie-consent-display {
    display: none;
}

.allow-button {
    height: 25px;
    width: 104px;
    color: #fff;
    font-size: 12px;
    line-height: 10px;
    border-radius: 3px;
    border: 1px solid #0096ff;
    background-color: #0096ff;
}

/* #scrollUp {
    width: 85px !important;
    height: 85px !important;
    bottom: 65px;
    right: 1px;
    background-color: #fff !important;
    background-image: url(../images/arrow_red.svg) !important;
    background-position-x: center !important;
    background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 42px 42px;
    border-radius: 50%;
    box-shadow: 0px 37px 46px 0px rgba(255, 0, 0, 0.07),0px 42px 27px 0px rgba(0, 0, 0, 0.05);
    transition-duration: 0.2s !important;
    transition-delay: 0s !important;
} */

.checkout-section{
    display: flex;
    justify-content: space-between;
}
.right-checkout-sec{
    width: 60%;
}

@media (max-width: 1199.98px) {
    /* #scrollUp {
        width: 40px;
        height: 40px;
        background-size: 50%;
    } */
    .cookie-consent {
        bottom: 0px;
        height: 25px;
        color: #fff;
        line-height: 10px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 10px;
        background: #292929;
        z-index: 120;
        cursor: pointer;
        border-radius: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cookie-consent-display {
        display: none;
    }

    .allow-button {
        height: 20px;
        width: 60px;
        color: #fff;
        font-size: 10px;
        line-height: 10px;
        border-radius: 3px;
        border: 1px solid #0096ff;
        background-color: #0096ff;
    }
}

.video-muted {
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: auto;
    bottom: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    transition: all 0.3s ease;
}

    .video-muted:hover,
    .video-muted:focus {
        color: #ff0000;
    }

.app-video-muted {
    float: right;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 1;
    top: auto;
    bottom: 40px;
    right: 20px;
    background-color: transparent;
    border: none;
    transition: all 0.3s ease;
}

/*--------------------------------------------------------------
#745
# COMMON STYLE END
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# NAVIGATION
--------------------------------------------------------------*/
.navbar {
    padding: 25px 30px;
}

.whiteBg {
    background-color: #fff;
}

@media (max-width: 1199.98px) {
    .css-1witaz7-control {
        min-width: 290px !important;
    }

    .navbar {
        padding: 25px 15px;
    }
}

@media (max-width: 750px) {
    .navbar {
        height: auto;
        padding: 0;
        color: black;
        text-align: center;
    }

    .navbar-dark {
        background-color: white !important;
    }

    .navbar-toggler {
        background-color: rgb(237, 237, 237) !important;
    }

    .hight {
        margin-top: 30px;
        height: 850px;
        background-color: white;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: black;
    }

    .ulalign {
        margin: 0 !important;
        width: 100%;
    }

    .clrtxtres {
        color: #000 !important;
    }

    .wid {
        width: 100%;
        margin-left: 100px;
    }
}

.navbar__nav-logo {
    width: 150px;
    height: auto;
}

/* .header__pages .navbar {
    padding: 34px 30px;
} */

@media (max-width: 1199.98px) {
    .header__pages .navbar {
        padding: 20px 15px;
        background: rgba(255, 255, 255, 1);
    }
}

.navbar-brand {
    margin-right: 45px;
}

.navbar .nav-item:not(:last-child) {
    margin-right: 50px;
}

@media (max-width: 1199.98px) {
    .navbar .nav-item:nth-child(n) {
        margin: 0 0 25px;
    }
}

.navbar .nav-item .nav-link-header {
    position: relative;
    z-index: 0;
    padding: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #fff;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}

.header__pages .navbar .nav-item .nav-link-header {
    color: #4c4c4c;
    font-weight: 400;
}

.serachMenu {
    color: #353535;
    font-size: 15px;
}

.searchMenuImg {
    content: url(../images/search-grey-icon.png);
}

.serachMenu_white {
    color: #fff;
    font-size: 15px;
}

.searchMenuImg_white {
    content: url(../images/search-icon.png);
}

@media (max-width: 1199.98px) {
    .navbar .nav-item .nav-link-header {
        padding: 10px 0;
        font-size: 18px;
        line-height: 18px;
    }

    .serachMenu {
        color: #353535;
        font-size: 18px;
    }

    .searchMenuImg {
        content: url(../images/search-grey-icon.png);
    }

    .serachMenu_white {
        color: #353535;
        font-size: 18px;
    }

    .searchMenuImg_white {
        content: url(../images/search-grey-icon.png);
    }
}

.navbar .nav-item.active .nav-link-header,
.navbar .nav-item .nav-link-header:hover,
.navbar .nav-item .nav-link-header:focus {
    color: #ff0000;
    opacity: 1;
}

@media (min-width: 1200px) {
    .navbar .nav-link-header:after {
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 3px;
        bottom: -10px;
        background-color: transparent;
        transition: all 0.3s ease-in-out;
    }

    .navbar .nav-item.active .nav-link-header:after,
    .navbar .nav-link-header:hover:after,
    .navbar .nav-link-header:focus:after {
        background-color: #fff;
    }
}

.header__events-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 1199.98px) {
    .header__events-list {
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        /* align-items: flex-start; */
        width: 100%;
    }
}

.header__events-item {
    margin: 0 0 0 10px;
}

@media (max-width: 1199.98px) {
    .header__events-item {
        width: 100%;
        margin: 0 0 10px;
        max-width: 300px;
        order: 1;
    }

    .header__events-dropdown {
        /* order: 0; */
        display: flex;
        justify-content: center;
    }
}

.header__events-item:nth-child(2) {
    padding-right: 10px;
    position: relative;
    z-index: 0;
}

@media (max-width: 1199.98px) {
    .header__events-item:nth-child(2) {
        padding-right: 0;
    }
}

@media (min-width: 1200px) {
    .header__events-item:nth-child(2)::after {
        content: '';
        position: absolute;
        z-index: 0;
        height: 17px;
        width: 1px;
        top: 6px;
        bottom: 0;
        left: auto;
        right: 0px;
        background-color: #ffffff;
    }
}

.header__events-favorite {
    border: none;
    padding: 0;
    background: transparent;
    transition: all 0.3s ease-in-out;
    fill: #fff;
    cursor: pointer;
}



.header__pages .header__events-favorite {
    font-size: 27px;
    line-height: 25px;
    transform: translateY(2px);
}

@media (max-width: 1199.98px) {
    .header__events-favorite {
        font-size: 18px;
        line-height: 18px;
    }
}

@media (max-width: 700px) {
    .heart-icon-white path,
    .heart-icon-white:focus path {
        fill: #353535 !important;
    }
}

.header__events-favorite:hover .heart-icon path,
.header__events-favorite:focus .heart-icon path {
    fill: #ff0000;
}

.heart-icon-red:hover path {
    fill: #fff;
}

.heart-icon-red path,
.heart-icon-red:focus path {
    fill: #ff0000;
}

.heart-icon-white:hover path {
    fill: #ff0000;
}

.heart-icon-white path,
.heart-icon-white:focus path {
    fill: #fff;
}

.heart-icon-grey:hover path {
    fill: #ff0000;
}

.heart-icon-grey path,
.heart-icon-grey:focus path {
    fill: #353535;
}

.header__events-btn {
    min-width: 103px;
    width: 100%;
    font-weight: 700;
    font-size: 10px;
    padding: 7px 4px 6px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 50px;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1199.98px) {
    .header__events-btn {
        font-size: 15px !important;
        display: block;
        margin: 0 !important;
    }

    .heartt {
        margin-top: 30px !important;
    }
}

.header__events-btn:hover,
.header__events-btn:focus {
    /* opacity: 0.8; */
    color: #fff;
    text-decoration: none;
}

.header__events-btn_app {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #353535;
}
.header__request-btn_code {
    min-width: 130px;
    display: flex;
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
    background-color: #F203FF;
    height: 34px;
    font-size: 12px;
    color: #fff;
    padding: 10px;
}

.header__events-btn_icon {
    font-size: 12px;
    line-height: 12px;
    margin-right: 5px;
}

.header__events-btn_event {
    display: inline-block;
    min-width: 103px;
    width: 100%;
    font-weight: 700;
    font-size: 10px;
    padding: 7px 4px 6px;
    background-color: #0096ff;
}

.header__events-dropdown .dropdown-menu {
    top: calc(100%) !important;
    background-color: #fff;
    border: none;
    padding: 0;
}

    .header__events-dropdown .dropdown-menu::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12px 13px 12px;
        border-color: transparent transparent #fff transparent;
        position: absolute;
        right: 21px;
        top: -13px;
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.3));
    }

@media (max-width: 1199.98px) {
    .header__events-dropdown .dropdown-menu::after {
        left: 10px;
    }
}

.header__events-btn_login {
    background-color: #ff0000;
    text-decoration: none;
    display: block;
}

.nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    /*font-size: 10px !important;
    font-weight: 700 !important;*/
    /* padding-left: 8px; */
    /* padding-top: 7px; */
}

.sticky {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
}

.sticky-bg {
    background: rgba(255, 255, 255, 1);
}

    .sticky-bg.navbar .nav-item .nav-link-header {
        color: #4c4c4c;
    }

.sticky.navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.5%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
    /* background-image: url(../../src/images/menu.png); */
}

.sticky-bg.navbar .navbar-toggler {
    border-color: rgba(0,0,0,.1);
}

.sticky-bg .header__events-favorite {
    fill: #4c4c4c;
}

.sticky-bg .heart-icon-white:hover path {
    fill: #ff0000;
}

.sticky-bg .heart-icon-white path,
.heart-icon-white:focus path {
    fill: #4c4c4c;
}

.sticky-bg .scrollToTop {
    display: none;
}
/*--------------------------------------------------------------
# NAVIGATION END
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# HEADER
--------------------------------------------------------------*/
.header__homepage {
    position: relative;
    height: 100vh;
}

.header__pages {
    background-color: #fff;
    padding-top: 127px;
}

.scrollToTop {
    display: block;
}

@media (max-width: 1199.98px) {
    .header__pages {
        padding-top: 99px;
    }
}

.header__bg {
    position: absolute;
    z-index: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.header__social {
    position: absolute;
    z-index: 0;
    left: 0;
    right: auto;
    top: auto;
    bottom: 50px;
    border-left: 3px solid #fe0000;
    padding-bottom: 40px;
}

.header__social-item {
    color: #fff;
    text-decoration: none;
    padding-left: 20px;
    margin-bottom: 18px;
}

.header__social-item_text {
    transform: translate(-24px,37px) rotate(-90deg );
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    padding-top: 0;
}

.header__social-link {
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    line-height: 13px;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.3s ease;
}

    .header__social-link:hover,
    .header__social-link:focus {
        filter: grayscale(1);
    }

@media (max-width: 1199.98px) {
    .header__social {
        padding-bottom: 0px;
        bottom: 0;
        display: flex;
        align-items: center;
    }

    .header__social-item_text {
        transform: none;
    }

    .header__social-item {
        margin-bottom: 7px;
    }
}

/* Mouse-scroll Down Button Start */
@-webkit-keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

@-moz-keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

@keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

.video-scroll {
    display: block;
    position: absolute;
    margin: 0 auto;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

@media (max-width: 768px) {
    .video-scroll {
        bottom: 30px;
    }
}

.video-scroll > a {
    display: inline-block;
    line-height: 18px;
    font-size: 13px;
    font-weight: normal;
    color: #7f8c8d;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
}

.video-scroll > *:hover,
.video-scroll > *:focus,
.video-scroll > *.active {
    color: #ffffff;
}

.video-scroll > *:hover,
.video-scroll > *:focus,
.video-scroll > *:active,
.video-scroll > *.active {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.video-scroll .mouse {
    position: relative;
    display: block;
    width: 30px;
    height: 45px;
    margin: 0 auto 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid rgba(225, 225, 225, 0.7);
    border-radius: 23px;
}

    .video-scroll .mouse > * {
        position: absolute;
        display: block;
        margin: 0 auto;
        top: 29%;
        left: 0;
        right: 0;
        width: 6px;
        height: 6px;
        background: rgba(225, 225, 225, 0.7);
        border-radius: 50%;
        -webkit-animation: ani-mouse 2.5s linear infinite;
        -moz-animation: ani-mouse 2.5s linear infinite;
        animation: ani-mouse 2.5s linear infinite;
    }
/* Mouse-scroll Down Button End */
/*--------------------------------------------------------------
# HEADER END
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# FOOTER START
--------------------------------------------------------------*/
.footer {
    padding: 63px 0 58px;
    background-color: #fff;
    margin-top: auto;
}

.footer__wrapper {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 991.98px) {
    .footer__wrapper {
        flex-wrap: wrap;
    }
}

.footer__nav-list {
    display: flex;
    align-items: center;
    max-width: 475px;
    width: 100%;
    margin: 0;
    padding: 0;
}

@media (max-width: 715.98px) {
    .footer__nav-list {
        flex-wrap: wrap;
    }
}

.footer__nav-item {
    position: relative;
    z-index: 0;
    margin-right: 30px;
}

    .footer__nav-item::after {
        content: '';
        position: absolute;
        width: 2px;
        height: 8px;
        top: auto;
        bottom: 8px;
        left: auto;
        right: -15px;
        background-color: #ff2d2d;
    }

    .footer__nav-item:last-child::after {
        display: none;
        margin-right: 0;
    }

.footer__nav-link {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #4c4c4c;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

    .footer__nav-link:hover,
    .footer__nav-link:focus {
        color: #ff2d2d;
    }

.footer__social {
    display: flex;
    align-items: center;
    margin: 0 20px 0 0;
}

@media (max-width: 715.98px) {
    .footer__social {
        padding: 40px 0 0;
        margin: 0;
    }
}

.footer__social-item {
    margin-right: 28px;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    color: #4c4c4c;
}

.footer__social-item_title {
    margin-right: 22px;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    color: #4c4c4c;
    letter-spacing: -0.5px;
}

.footer__social-link {
    transition: all 0.3s ease;
}

    .footer__social-link:hover,
    .footer__social-link:focus {
        filter: grayscale(1);
    }

.footer__social-item:last-child {
    margin-right: 0;
}

.footer__copyright {
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #4c4c4c;
    margin: 0;
    letter-spacing: 1.3px;
}

@media (max-width: 991.98px) {
    .footer__copyright {
        width: 100%;
        padding-top: 40px;
    }
}

/*--------------------------------------------------------------
# FOOTER END
--------------------------------------------------------------*/

/*------------------------------------------------------------------------
################## HOME PAGE START
-------------------------------------------------------------------------*/

/*--------------------------------------------------------------
# MODALS START
--------------------------------------------------------------*/

/*contacts modal start*/
._2qp0Z {
    position: inherit !important;
    /* margin-top: -50px; */
    margin-bottom: 25px;
}

._1yCVn {
    position: inherit !important;
}

.contacts__modal-dialog {
    max-width: 100%;
    height: 100%;
    margin: 0;
}

.contacts__modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: #e5e5e5;
    padding-bottom: 30px;
}

@Media (max-width: 960px) {
    .contacts__modal-content {
        justify-content: start;
        height: auto;
    }

    .StripeElement {
        width: 100% !important;
    }
    label{
        width: 100% ;
    }
    .payments__form-wrap {
        text-align: center !important;
    }
}

.contacts__close {
    /* position: absolute; */
    z-index: 0;
    margin: -25px auto 0 auto;
    /* left: 0;
    right: 0;
    top: 78%; */
    /* bottom: 80px; */
    /* margin-inline-start: 50%; */
}

@media (max-width: 900px) {
    .contacts__close-icon {
        top: 10px;
        bottom: auto;
        left: auto;
        right: 10px;
    }

    .contacts__close {
        position: absolute;
        z-index: 0;
        margin: 0 auto;
        left: 150px;
        right: 0;
        top: 93%;
        bottom: 20px;
        margin-inline-start: 50%;
    }
}

.contacts__container {
    max-width: 930px;
    /*width: 100%;*/
    margin: 0 auto;
    padding: 132px 0px;
    text-align: center;
}

@media (max-width: 1080px) {
    .contacts__container {
        padding: 10px 0px;
    }
}

.contacts__logo-wrapper {
    margin-bottom: 113px;
}

@media (max-width: 660px) {
    .contacts__logo-wrapper {
        /* text-align-last: left; */
        margin-bottom: 20px;
        /* display: flex; */
    }
}

.contacts__logo-image {
    max-width: 362px;
    height: auto;
}

@media (max-width: 660px) {
    .contacts__logo-image {
        max-width: 45%;
    }
}

.contacts__content-box {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    text-align: left;
    /* width: 850px; */
}

@media (max-width: 900px) {
    .contacts__content-box {
        flex-direction: column;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }
}

.contacts__touch-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
}

@media (max-width: 900px) {
    .contacts__touch-wrapper {
        max-width: 100%;
        margin-bottom: 25px;
        padding: 0 10px 28px;
        /* border-bottom: 2px solid #4c4c4c; */
    }
}

@media (max-width: 660px) {
    .contacts__touch-wrapper {
        margin-bottom: 10px;
        /* padding: 0 10px 10px; */
    }

    .contacts__touch-wrapper {
        padding: 30px;
    }

    .whtsapp {
        margin-top: 50px;
    }

    .contacts__follow-input {
        margin-top: 50px;
    }
}

.contacts__touch-link,
.contacts__touch-tel {
    /* margin-bottom: 20px; */
    display: inline-block;
    vertical-align: middle;
    font-size: 17px;
    line-height: 30px;
    color: #4c4c4c;
    text-decoration: none;
}

@media (max-width: 660px) {
    .contacts__touch-link {
        margin-bottom: 0;
    }

    .contacts__touch-tel {
        margin-top: 50px;
    }
}

.touch__link-icon {
    padding-right: 14px;
}

.contacts__touch-tel {
    /* margin-bottom: 20px; */
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
}

@media (max-width: 660px) {
    .contacts__touch-tel {
        margin-bottom: 15px;
    }

    .h3whatsapp {
        font-size: 20px;
    }
}

.contacts__tel-icon {
    /* padding-right: 10px; */
    width: 100%;
    margin-left: -10px;
}

.h3whatsapp {
    font-weight: 800;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    text-decoration: none;
}

.pwhatsapp {
    font-size: small;
}

.contacts__list-item {
    margin-right: 26px;
}

.contacts__touch-tel:hover {
    text-decoration: none !important;
    color: #4c4c4c;
}

@media (max-width: 940px) {
    .contacts__list-item {
        margin-right: 20px;
    }

    .emaillink {
        margin-left: 15px !important;
        margin-top: 15px !important;
    }
}

.contacts__list-item:last-child {
    margin-right: 0;
}

.contacts__touch-list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /*max-width: 385px;*/
    width: 100%;
    text-align: left;
    justify-content: center;
}

.emaillink {
    margin-left: 150px;
    margin-top: -30px;
}

.contacts__list-link {
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    color: #4c4c4c;
    text-decoration: none;
    white-space: nowrap;
}

@media (max-width: 660px) {
    .contacts__list-link {
        font-size: 15px;
    }

    .contacts__touch-list {
        display: none;
    }

    .contacts__follow-wrapper {
        margin-top: 20px;
        padding: 20px;
    }
}

.contacts__follow-wrapper {
    max-width: 380px;
    width: 100%;
    margin-top: -3px;
}

@media (max-width: 900px) {
    .contacts__follow-wrapper {
        max-width: 100%;
        padding: 0 45px;
    }
}


.contacts__touch-title,
.contacts__follow-title {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #4c4c4c;
}

@media (max-width: 660px) {
    .contacts__touch-title,
    .contacts__follow-title {
        margin-bottom: 0;
        margin-top: 10px;
    }

    .contacts__touch-title {
        display: none;
    }
}

.contacts__touch-description,
.contacts__follow-description {
    margin-bottom: 20px;
    font-size: small;
    line-height: 19px;
    color: #313131;
    width: 100%;
}

.contacts__follow-description {
    margin-bottom: 35px;
    margin-top: 10px;
}

@media (max-width: 660px) {
    .contacts__touch-description,
    .contacts__follow-description {
        margin-bottom: 10px;
    }

    .contacts__touch-description {
        display: none;
    }
}

.contacts__follow-input {
    display: block;
    max-width: 370px;
    width: 100%;
    position: relative;
    z-index: 0;
    margin-bottom: 66px;
}

@media (max-width: 660px) {
    .contacts__follow-input {
        margin-bottom: 15px;
    }
}

.contacts__follow-btn {
    position: absolute;
    z-index: 0;
    max-width: 94px;
    height: 36px;
    top: auto;
    bottom: 1px;
    left: auto;
    right: 0;
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    background-color: #da0000;
    border: none;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    cursor: pointer;
}

.contacts__follow-email {
    width: 100%;
    height: 38px;
    padding: 5px 100px 5px 26px;
    border: 1px solid #bebebe;
    border-radius: 7px;
}

.contacts__follow-social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 120px;
    width: 100%;
    margin: 0;
}

.modal {
    padding-right: 0 !important;
}

.contacts__social-link:hover,
.contacts__social-link:focus {
    filter: grayscale(1);
}


/*contacts modal end*/
/*--------------------------------------------------------------
# MODALS END
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# FEATURED SECTION START
--------------------------------------------------------------*/
.featured {
    /* padding-top: 51px; */
    background-color: #fff;
}

/* Chess Board Video */
.featured__chessboard-wrapper {
    max-width: 100%;
    width: 100%;
    height: 41px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.featured__chessboard-video {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
/* Chess Board Video End*/

.featured__carousel-wrapper {
    position: relative;
    z-index: 0;
    /* margin-bottom: 175px; */
    margin-bottom: 75px;
}

.featured__carousel-box {
    background: #151515;
}

.featured__carousel-wr {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featured__title {
    padding-top: 70px;
    margin-bottom: 88px;
}

.featured__carousel-item {
    border: 15px solid #151515;
    /*max-height: 675px !important;*/
    overflow: hidden;
}

.featured__carousel-img {
    /* width: 100%;
    max-height: 675px;*/
    width: auto;
    height: auto;
}

.featured__carousel-arrows {
    text-align: center;
    font-size: 50px;
    line-height: 20px;
}

.featured__carousel-arrow {
    position: absolute;
    z-index: 0;
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.5);
}

    .featured__carousel-arrow:first-child {
        top: 88px;
        bottom: auto;
        left: 50px;
        right: auto;
    }

    .featured__carousel-arrow:last-child {
        top: 88px;
        bottom: auto;
        left: auto;
        right: 50px;
    }

    .featured__carousel-arrow:hover,
    .featured__carousel-arrow:focus {
        color: #ff0d0d;
    }

.featured__select-row {
    display: flex;
    align-items: center;
    max-width: 970px;
    width: 100%;
    margin: auto
}
@media (max-width: 985px) {
    .featured__select-row {
        width: 100%;
        order: 0;
        flex-direction: column;
        max-width: 600px;
        align-items: center;
    }
}

@media (max-width: 660px) {
    .featured__select-row {
        max-width: 400px;
    }
}

.featured__select-wrapper {
    display: flex;
    justify-content: left;
    align-items: stretch;
    position: relative;
    width: 100%;
    padding: 0 0 0 27px;
    background-color: #fff;
    border-style: solid;
    border-width: 7px 9px 7px 7px;
    border-color: #ff0d0d;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

@media (max-width: 1000px) {
    .featured__select-wrapper {
        order: 1;
        padding: 15px 10px 15px 10px;
        border-radius: 50px;
    }
}

@media (max-width: 970px) {
    .featured__select-wrapper {
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 777px) and (min-width: 300x) and (orientation: landscape) {
    .featured__select-wrapper {
        width: 70%;
    }
}

@media (width: 1024px) {
    .featured__select-wrapper {
        margin-left: -108px;
        width: 705px;
    }
}

@media (width: 823px) {
    .featured__select-wrapper {
        width: 122%;
    }
}

@media (mex-width: 750px) {
    .featured__select-wrapper {
        width: 122%;
    }
}

@media (width: 812px) {
    .featured__select-wrapper {
        width: 122%;
    }
}

.featured__select-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    /* z-index: 0; */
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 985px) {
    .featured__select-box {
        margin: 0;
    }
}

@media (max-width: 660px) {
    .featured__select-box {
        margin-bottom: 20px;
    }

        .featured__select-box:first-child {
            max-width: 100% !important;
        }

        .featured__select-box:nth-child(2) {
            max-width: 100% !important;
        }

        .featured__select-box:nth-child(3) {
            max-width: 100% !important;
        }

    .css-dbpm2d-control {
        min-width: 300px !important;
    }
}

.featured__select-box:first-child {
    max-width: 264px;
}

.featured__select-box:nth-child(2) {
    max-width: 320px;
    padding-left: -2px;
}

.featured__select-box:nth-child(3) {
    max-width: 300px;
    /* padding-left: 11px; */
}

@media (max-width: 985px) {
    .featured__select-box:first-child {
        max-width: 200px;
    }

    .featured__select-box:nth-child(2) {
        max-width: 200px;
    }

    .featured__select-box:nth-child(3) {
        max-width: 182px;
    }
}

.featured__select-box::after {
    content: '';
    position: absolute;
    z-index: 0;
    width: 3px;
    height: 60px;
    background-color: #e3e3e3;
    top: 20px;
    bottom: auto;
    right: 1px;
}

@media (max-width: 985px) {
    .featured__select-box::after {
        display: none;
    }
}

.featured__select-box:nth-child(2):after {
    right: 0;
}

.featured__select-box:nth-child(3)::after {
    display: none;
}

.featured__select-name {
    width: 100%;
    background-color: inherit;
    border: none;
    border-radius: 2px;
    background-color: #fff;
    color: #575757;
    font-size: 18px;
    line-height: 34px;
    font-weight: 600;
    text-align: center;
    font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
}

    .featured__select-name::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #575757;
        opacity: 1;
    }

    .featured__select-name::-moz-placeholder { /* Firefox 19+ */
        color: #575757;
        opacity: 1;
    }

    .featured__select-name:-ms-input-placeholder { /* IE 10+ */
        color: #575757;
        opacity: 1;
    }

    .featured__select-name:-moz-placeholder { /* Firefox 18- */
        color: #575757;
        opacity: 1;
    }

@media (max-width: 985px) {
    .featured__select-name {
        padding-left: 10px;
        text-align: left;
    }
}

.featured__select-wrapper select,
.featured__select-name:focus {
    outline: none;
}

.featured__select-wrapper .selection {
    display: block;
}

.featured__select-wrapper .select2,
.featured__select-wrapper .select2-container--default .select2-selection--single,
.featured__select-wrapper .select2-container--default .select2-selection--single,
.featured__select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered,
.featured__select-wrapper .select2-container--default .select2-selection--single {
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0;
    height: 26px;
    color: #575757;
    font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
    font-size: 20px !important;
    line-height: normal !important;
    font-weight: 500 !important;
    text-align: center;
    outline: none !important;
}

    .featured__select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered {
        display: inline-block !important;
        position: relative !important;
    }

@media (max-width: 985px) {
    .featured__select-wrapper .select2,
    .featured__select-wrapper .select2-container--default .select2-selection--single,
    .featured__select-wrapper .select2-container--default .select2-selection--single,
    .featured__select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered,
    .featured__select-wrapper .select2-container--default .select2-selection--single {
        text-align: left;
    }
}

.featured__select-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none;
}

.featured__select-wrapper .select2-selection__rendered::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    top: auto;
    bottom: 3px;
    right: -1px;
    background: url('../images/featured__select-arrow.svg');
    background-size: auto;
    background-size: 100% 100%;
    transform: rotate(180deg);
}

.featured__select-box_align .select2-selection__rendered::after {
    right: 0;
}

@media (max-width: 985px) {
    .featured__select-wrapper .select2-selection__rendered::after {
        right: 18px;
    }
}

.featured__btn__wrapper {
    margin-left: auto;
    padding: 30px 10px 30px 25px;
    background-color: #f8f8f8;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

@media (max-width: 985px) {
    .featured__btn__wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        background-color: transparent;
    }
}

@media (min-width: 1024px) {
    .featured__btn__wrapper {
        /* margin-left: -46px;*/
        width: 110px;
    }
}

.featured__select-btn {
    width: 100%;
    padding: 8px 25px;
    background-color: rgb(255, 0, 0);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 10px 21px 0px rgba(219, 22, 35, 0.68);
    opacity: 0.9;
    cursor: pointer;
    transition: 0.5s;
}

    .featured__select-btn:hover,
    .featured__select-btn:focus {
        background-color: #575757;
    }

        .featured__select-btn:hover path,
        .featured__select-btn:focus path {
            fill: #fe1818;
        }


.featured__select-img {
    display: block;
    max-width: 100%;
    max-height: 113px;
    margin-left: -11px;
}

@media (min-width: 1600px) and (max-width: 2500px) {
    .featured__select-img {
        max-height: 113px;
    }
}

@media (max-width: 985px) {
    .featured__select-img {
        margin-left: 0;
        width: 127px;
    }
}
/*--------------------------------------------------------------
# FEATURED SECTION END
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# UPCOMING SECTION START
--------------------------------------------------------------*/
.upcoming {
    padding: 111px 0 50px;
}

@media (max-width: 974.98px) {
    .upcoming {
        padding: 50px 0;
    }
}

.upcoming__title {
    margin-bottom: 90px;
}

.upcoming__menu-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 60px;
}

.upcoming__menu {
    display: flex;
    align-items: center;
    max-width: 885px;
    width: 100%;
    margin: 0 auto;
}

@media (max-width: 974.98px) {
    .upcoming__menu {
        flex-wrap: wrap;
    }
}

.upcoming__menu-item {
    width: 100%;
    margin: 0 20px 18px 0;
}

@media (max-width: 974.98px) {
    .upcoming__menu-item {
        width: auto;
        margin: 0 15px 15px 0;
    }
}

.upcoming__menu-item_all {
    position: relative;
    z-index: 0;
    margin-right: 20px;
}

.upcoming__btn-optional_all::after {
    position: absolute;
    width: 2px;
    height: 32px;
    background-color: #636363;
    top: auto;
    bottom: 5px;
    left: auto;
    right: -12px;
}

.upcoming__btn-optional_all:hover,
.upcoming__btn-optional_all:focus {
    background-color: #ff0d0d;
}

.upcoming__menu-item:last-child {
    margin-right: 20px;
}

.upcoming__btn-optional {
    min-width: 111px;
    width: 100%;
    padding: 13px 10px 12px;
    font-weight: 600;
    font-size: 13px;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out
}

    .upcoming__btn-optional:hover,
    .upcoming__btn-optional:focus {
        transform: translate3d(0,-3px,0);
        filter: grayscale(30%);
    }

@media (max-width: 974.98px) {
    .upcoming__btn-optional {
        min-width: 82px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 11px;
    }
}
@media (min-width: 391px) and (max-width: 414px) {
    .upcoming__btn-optional {
        min-width: 80px;
    }
}
@media (min-width: 376px) and (max-width: 390px) {
    .upcoming__btn-optional {
        min-width: 75px;
    }
}
@media (min-width: 350px) and (max-width: 375px) {
    .upcoming__btn-optional {
        min-width: 76px;
    }
    .upcoming__menu-item {
        margin: 0 5px 15px 0 !important;
    }
    .upcoming__menu-item_all{
        margin-right: 0;
    }
}
@media (min-width: 320px) and (max-width: 349px) {
    .upcoming__btn-optional {
        min-width: 65px;
    }
    .upcoming__menu-item {
        margin: 0 3px 15px 0 !important;
    }
    .upcoming__menu-item_all{
        /* min-width: 70px; */
        margin-right: 0;
    }
    .confirmation__title{
        line-height: 40px !important;
    }
    .confirmation__text{
        width: 270px !important;
        margin-left: 15px !important;
        margin-top: 15px !important;
    }
}
.upcoming__btn-optional_all {
    min-width: 70px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    background-color: #616161;
    text-transform: none;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}

.upcoming__btn-optional_orang-dark {
    background: linear-gradient(0.25turn, #f0cc1d, #db8a0b);
}

.upcoming__btn-optional_pink-dark {
    background: linear-gradient(0.25turn, #ff5457, #ff4292);
}

.upcoming__btn-optional_pink-light {
    background: linear-gradient(0.25turn, #c80066, #e00026);
}

.upcoming__btn-optional_orang-light {
    background: linear-gradient(0.25turn, #ffd80e, #ff8e02);
}

.upcoming__btn-optional_blue-light {
    background: linear-gradient(0.25turn, #13d5fd, #0192ca);
}

.upcoming__btn-optional_blue-dark {
    background: linear-gradient(0.25turn, #0fecac, #00b2f3);
}

.upcoming__btn-arrow {
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 20px 0 0 20px;
}

.upcoming__arrow-icon {
    font-size: 25px;
    line-height: 25px;
    font-weight: 700;
    color: #515151;
}

.upcoming__btn-arrow[aria-expanded="false"] {
    transform: rotate(180deg);
}

.upcoming__cart-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 90px;
    column-gap: 30px;
    max-width: 1080px;
    width: 100%;
    margin: 0 auto 118px;
}

@media (max-width: 1199.98px) {
    .upcoming__cart-list {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 70px;
    }
}

@media (max-width: 776.98px) {
    .upcoming__cart-list {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }
}

.upcoming__cart-item {
    background-color: #fff;
    padding-bottom: 37px;
    border-radius: 10px;
    box-shadow: 0 0px 35px -20px;
    box-shadow: 0px 6px 25.11px 1.89px rgba(0, 0, 0, 0.15);
}

.upcoming__cart-wrap {
    overflow: hidden;
    --webkit-backface-visibility: hidden;
    --moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.upcoming__cart-image {
    width: 100%;
    height: 234px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.upcoming__cart-item:hover .upcoming__cart-image,
.upcoming__cart-item:focus .upcoming__cart-image {
    transform: scale(1.1);
}

.upcoming__cart-content {
    position: relative;
    z-index: 0;
    padding: 20px 17px 0;
}

.upcoming__cart-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.upcoming__cart-title {
    margin: 0;
    font-weight: 700;
    font-size: 21px;
    line-height: 35px;
    color: #ff0000;
    letter-spacing: -0.5px;
}

.upcoming__cart-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-14px);
}

.upcoming__price-from {
    display: block;
    font-style: italic;
    font-weight: 300;
    font-size: 10px;
    line-height: 36px;
    color: #252525;
}

.upcoming__price {
    display: block;
    font-weight: 700;
    font-size: 19px;
    line-height: 0;
    color: #20b2f0;
}

.upcoming__date-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

    .upcoming__date-item:first-child {
        margin-bottom: 15px;
    }

.upcoming__calendar-icon {
    display: block;
    transform: translateX(-3px);
    margin-right: 5px;
}

.upcoming__date-text {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #818181;
}

.upcoming__date-text_big {
    font-size: 17px;
    line-height: 17px;
}

.upcoming__date-link {
    text-decoration: none;
    opacity: 0.4;
    transition: all 0.3s ease;
    background-color: transparent;
    border: none;
}

    .upcoming__date-link.active {
        opacity: 1;
    }

        .upcoming__date-link.active svg path {
            fill: #ff3115;
        }

    .upcoming__date-link:hover,
    .upcoming__date-link:focus {
        transform: translateY(5px);
    }

.upcoming__calendar-number {
    color: #ff3115;
}

.upcoming__date-sun {
    color: #252525;
}

.upcoming__location-icon {
    margin-right: 10px;
}

.upcoming__location-text {
    color: #20b2f0;
}

.upcoming__date-item_space-between {
    justify-content: space-between;
}

.upcoming__time-wrap {
    display: flex;
    align-items: center;
}

.upcoming__clock-icon {
    margin-right: 10px;
}

.upcoming__card-btn {
    position: absolute;
    z-index: 0;
    top: auto;
    bottom: -57px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 180px;
    width: 100%;
    padding: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    background-color: #fd1717;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0px 15px 27px 0px rgba(219, 22, 35, 0.45);
}

    .upcoming__card-btn:hover,
    .upcoming__card-btn:focus {
        background-color: #dd2504;
        transform: translate3d(0,-3px,0);
    }

.upcoming__btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.upcoming__all-events {
    max-width: 155px;
    width: 100%;
    padding: 5px;
    font-weight: 600;
    font-size: 15px;
    line-height: 37px;
    color: #fff;
    text-decoration: none;
    background-color: #afafaf;
    border: none;
    border-radius: 25px;
    margin: 0 6px;
    text-align: center;
}

    .upcoming__all-events:hover,
    .upcoming__all-events:focus {
        background-color: #0096ff;
        color: #fff;
        text-decoration: none;
        transform: translate3d(0,-3px,0);
    }

.upcoming__btn-wrap_line {
    max-width: 390px;
    width: 100%;
    cursor: none;
    height: 1px;
    background-color: #ccc;
    border: none;
    margin: 0;
    transition: all 0.4s ease;
}
.events__select-wrapper .featured__select-name {
    border: 1px solid #4c4c4c !important;
    border-radius: 30px!important;
    margin-right: 15px;
}
/*--------------------------------------------------------------
# UPCOMING SECTION END
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# GET APP
--------------------------------------------------------------*/
.get-app {
    padding-top: 74px;
}

@media (max-width: 991.98px) {
    .get-app {
        padding-top: 20px;
    }
}

.get-app__title {
    margin-bottom: 6px;
}

    .get-app__title::after {
        display: none;
    }

.get-app__title-description {
    margin: 0 0 47px;
    font-weight: 400;
    font-size: 20px;
    line-height: 37px;
    color: #000;
    text-align: center;
    position: relative;
    z-index: 0;
}

.get-app__title-description:after {
    content: '';
    position: absolute;
    max-width: 105px;
    width: 100%;
    height: 3px;
    margin: 0 auto;
    top: auto;
    bottom: -31px;
    left: 0;
    right: 0;
    background-color: #ff0d0d;
    bottom: -13px;
}
.bottom-red{
    position: relative;
    margin-bottom: 100px;
}
.bottom-red:after{
    content: '';
    position: absolute;
    max-width: 150px;
    width: 100%;
    height: 10px;
    margin: 0 auto;
    top: auto;
    bottom: -30px;
    left: 0;
    right: 0;
    background-color: #ff0d0d;
}
.get-app__wrapper {
    display: flex;
    align-items: stretch;
}
.payments__data-title{
    margin-bottom: 0 !important;  
}
.payments__form-item p{
    font-size: 13px;
    color: #353b48;
    font-weight: 500;
}
@media (max-width: 756px) {
    .get-app__wrapper {
        flex-wrap: wrap;
    }
    .featured__select-name {
        border: 1px solid #dedede !important;
    }
    .cutsom-select-hash{
        margin-right: 0 !important;
    }
    .events__select-wrapper .featured__select-name{
        margin-right: 0 !important;
    }
    .events__cart-list .upcoming__card-btn{
        max-width: 220px !important;
        padding: 15px 5px !important;
    }
    .links-section{
        padding-left: 70px !important;
    }
    .payments__data-wrapper .navbar-toggler{
        background-color: transparent !important;
    }
    .payments__data-wrapper .navbar-toggler.collapsed svg{
        rotate: -180deg;
    }

}

.get-app__upload {
    width: 63%;
}

@media (max-width: 1000px) {
    .get-app__upload {
        width: 50%;
    }
}

@media (max-width: 756px) {
    .get-app__upload {
        width: 100%;
    }
}

.get-app__upload-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

    .get-app__upload-icon::-webkit-media-controls-panel {
        display: none !important;
        -webkit-appearance: none;
    }


.get-app__number-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 50px 15px; */
    width: 60%;
    background: url(../images/bg_fog.png) center center no-repeat;
    background-size: cover;
}

.get-app__number-wrappp {
    display: flex;
    flex-direction: column;
    margin-left: 7%;
    margin-right: 15px;
}

.qrcodeflx {
    display: flex;
}

.qrcodeimg {
    /* margin-left: 10%; */
    margin-top: 20%;
    width: 150px;
}

.qrcodelbl {
    color: #fff;
    font-size: small;
    margin-top: 5px;
    margin-left: 5px;
}

@media (max-width: 1000px) {
    .get-app__number-wrap {
        width: 50%;
    }
}

@media (max-width: 756px) {
    .get-app__number-wrap {
        width: 100%;
    }
}

.get-app__number-text {
    margin-bottom: 27px;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #fff;
}

.get-app__number-input {
    max-width: 340px;
    width: 100%;
    height: 46px;
    margin: 0 auto 12px;
    padding: 0 32px;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    border: 1px solid #fff;
    border-radius: 25px;
    background: transparent;
    color: #fff;
}

.get-app__number-input-new {
    max-width: 340px;
    width: 100%;
    height: 46px;
    margin: 0 auto 12px;
    padding: 0 32px;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    border: 1px solid #000;
    border-radius: 25px;
    background: transparent;
    color: #000;
}

.get-app__number-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
}

.get-app__number-input::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
}

.get-app__number-input:-ms-input-placeholder { /* IE 10+ */
    color: #fff;
}

.get-app__number-input:-moz-placeholder { /* Firefox 18- */
    color: #fff;
}

.get-app__number-input-new::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #000;
}

.get-app__number-input-new::-moz-placeholder { /* Firefox 19+ */
    color: #000;
}

.get-app__number-input-new:-ms-input-placeholder { /* IE 10+ */
    color: #000;
}

.get-app__number-input-new:-moz-placeholder { /* Firefox 18- */
    color: #000;
}

.get-app__number-btn {
    max-width: 340px;
    width: 100%;
    margin: 0 auto;
    padding: 11px 0 10px;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #fff;
    background-color: #e11112;
    text-transform: none;
    border: none;
    border-radius: 25px;
    box-shadow: 0px 15px 27px 0px rgba(219, 22, 35, 0.45);
}

    .get-app__number-btn:hover,
    .get-app__number-btn:focus {
        background-color: #dd2504;
        transform: translate3d(0,-3px,0);
    }

/*--------------------------------------------------------------
# GET APP END
--------------------------------------------------------------*/

/*------------------------------------------------------------------------
################## HOME PAGE END
-------------------------------------------------------------------------*/

/*------------------------------------------------------------------------
################## EVENTS PAGE START
-------------------------------------------------------------------------*/
.events {
    padding-bottom: 10px;
}

.events__title-bg {
    background: #000 url(../images/events_bg.png) center center no-repeat;
    background-size: cover;
    padding: 68px 15px 82px;
    text-align: center;
}

.events__title {
    color: #fff;
    font-size: 53px;
    line-height: 55px;
    letter-spacing: -2px;
    display: inline-block;
    vertical-align: middle;
}

    .events__title::after {
        height: 5px;
        width: 100%;
        max-width: 100%;
        bottom: -12px;
    }

.events__filters-wrapper {
    display: flex;
    align-items: center;
    max-width: 1165px;
    width: 100%;
    margin: 0 auto;
    padding: 97px 0 80px;
}

@media (max-width: 1320px) {
    .events__filters-wrapper {
        flex-wrap: wrap;
        padding-bottom: 40px;
    }
}

@media (max-width: 756px) {
    .events__filters-wrapper {
        padding-top: 50px;
        flex-direction: column;
    }

    .alignrespons {
        width: 150%;
    }

    .res {
        margin-top: 15px;
    }

    .events-range-alignres {
        margin-left: -100px !important;
    }
}

.events__range {
    padding: 48px 58px 0 0
}

@media (max-width: 1320px) {
    .events__range {
        margin-bottom: 40px;
        padding-right: 0;
    }
}

/*jrange start*/
.events-range-alignres {
    width: 50%;
    /* margin-left: -85px; */
}

.events__range .back-bar {
    height: 3px;
}

    .events__range .back-bar .selected-bar {
        background: #e90101;
    }

.events__range .scale {
    top: -55px;
}

    .events__range .scale span {
        border-left: none;
    }

    .events__range .scale > span:before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #e90101;
        position: absolute;
        z-index: 0;
        top: 50px;
        left: -5px
    }

    .events__range .scale > span:first-child ins {
        margin-left: -5px !important;
    }

    .events__range .scale ins {
        color: #4c4c4c;
        font-size: 18px;
        line-height: 18px;
        font-weight: 500;
        white-space: nowrap;
    }

.events__range .back-bar .pointer {
    width: 32px;
    height: 32px;
    top: -16px;
    border-radius: 50%;
    background: #ff2626;
    border: 5px solid #e90101;
}
/*jrange end*/

.events__select-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: row; */
    width: 100%;
    margin-top: 25px;
}

@media (max-width: 756px) {
    .events__select-wrapper {
        flex-wrap: wrap;
        width: 100%;
    }
}

/*select2 start*/
.events__select-wrapper .select2 {
    min-width: 168px;
    margin: 0 17px 17px 0;
}

.events__select-wrapper .select2-container--default .select2-selection--single {
    border: 1px solid #4c4c4c;
    border-radius: 32px;
    height: 43px;
    text-align: center;
}

.events__select-wrapper .select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.events__select-wrapper .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 32px;
}

.events__select-wrapper .select2-container--open .select2-dropdown--below {
    width: 198px !important;
    border-radius: 20px;
    overflow: hidden;
    transform: translate(-15px, 4px);
    border: none;
    box-shadow: 0px 21px 46px 0px rgba(31, 31, 31, 0.39);
}

.events__select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #4c4c4c;
    font-size: 15px;
    line-height: 43px;
    font-weight: 600;
}

.selectize-dropdown {
    margin: 5px 0 0 0;
    border-radius: 20px;
    background-color: rgb(249, 249, 249);
    box-shadow: 0px 21px 46px 0px rgba(31, 31, 31, 0.39);
    width: 198px !important;
    color: #0e0e0e;
    font-size: 15px;
    line-height: 15px;
    padding: 0;
    font-weight: 500;
    overflow: hidden;
}

.events__select-wrapper .select2-results__option--selectable {
    cursor: pointer;
    padding: 10px 30px;
}

.events__select-wrapper .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #c2c2c2;
    color: #0e0e0e;
}

.events__select-wrapper .select2-selection__placeholder {
    color: #4c4c4c;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
}

.events__select-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow {
    content: url(../images/arr_select.png);
    border: none;
    top: 20px;
    right: 17px;
    width: auto;
    height: auto;
}

.events__select-wrapper .select2-container--default .select2-results > .select2-results__options {
    max-height: 225px;
}

.events__filters-btn {
    /* max-width: 181px; */
    width: 100%;
    padding: 15px 0 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: #fff;
    background-color: #0096ff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    /* margin-bottom: 17px; */
}

    .events__filters-btn:hover,
    .events__filters-btn:focus {
        transform: translate3d(0,-3px,0);
        background-color: #0784dc;
    }

/*select2 end*/

.events__cart-list {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 113px;
    column-gap: 39px;
    position: relative;
    z-index: 0;
    max-width: 1356px;
}

@media (max-width: 1199.98px) {
    .events__cart-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 776.98px) {
    .events__cart-list {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }
}

.events__cart-list .upcoming__cart-item {
    padding-bottom: 42px;
}

.events__cart-list .upcoming__cart-image {
    height: 295px;
}

.events__cart-list .upcoming__cart-content {
    padding: 23px 23px 0;
}

.events__cart-list .upcoming__cart-box {
    margin-bottom: 13px;
}

.events__cart-list .upcoming__cart-title {
    font-size: 26px;
    line-height: 44px;
}

.events__cart-list .upcoming__cart-price {
    transform: translateY(-11px);
}

.events__cart-list .upcoming__price-from {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 12px;
}

.events__cart-list .upcoming__price {
    font-size: 23px;
    color: #20b2f0;
}

.events__cart-list .upcoming__date-item {
    margin-bottom: 10px;
}

    .events__cart-list .upcoming__date-item:first-child {
        margin-bottom: 16px;
    }

    .events__cart-list .upcoming__date-item:last-child {
        margin-bottom: 25px;
    }

.events__cart-list .upcoming__date-text {
    font-size: 18px;
    line-height: 18px;
}

.events__cart-list .upcoming__date-text_big {
    font-size: 21px;
    line-height: 21px;
    font-weight: 600;
}

.events__cart-list .upcoming__calendar-icon {
    display: block;
    transform: translateX(-2px);
    margin-right: 9px;
}

.events__cart-list .upcoming__card-btn {
    max-width: 180px;
    font-size: 20px;
    padding: 9px 5px;
    bottom: -86px;
}

/*------------------------------------------------------------------------
################## EVENTS PAGE END
-------------------------------------------------------------------------*/

/*------------------------------------------------------------------------
################## ORDER PAGE START
-------------------------------------------------------------------------*/
.order {
    padding: 0 0 50px;
}

/*.order__title-bg {
    background: #000 url(../images/order_bg.jpg) center center no-repeat;
    background-size: cover;
    padding: 68px 15px 184px;
    text-align: center;
    opacity: 0.5;
}*/

.order__title-bg-clear {
    background: #000 url(../images/order_bg.jpg) center center no-repeat;
    background-size: cover;
    padding: 68px 15px 184px;
    text-align: center;
}

.order__title-bg {
    background: #000 url(../images/order_bg.jpg) center center no-repeat;
    background-size: cover;
    padding: 68px 15px 184px;
    text-align: center;
    filter: blur(1px);
    position: relative;
}

    .order__title-bg::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
    }

.order__title {
    color: #fff;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -2px;
    display: inline-block;
    vertical-align: middle;
}

.order__title1 {
    color: #000;
    font-size: 22px;
    line-height: 40px;
    display: inline-block;
    vertical-align: middle;
}

/* @media (max-width: 992px) {
    .order__title-bg {
        padding: 10px 0;
    }
} */

/*.order__title {
    color: #fff;
    font-size: 53px;
    line-height: 55px;
    letter-spacing: -2px;
    display: inline-block;
    vertical-align: middle;
}*/

.order__title::after {
    height: 5px;
    max-width: 170px;
    bottom: -23px;
}

.order__tickets-reserved {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto 51px;
    padding-bottom: 43px;
    border-bottom: 2px solid #dcdcdc;
}

.order__tickets-reserved-min {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
}

.order__tickets-time {
    background-color: #0096ff;
    text-align: center;
}

.order__tickets-description {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 6px 0 5px;
}

.order__tickets-timer {
    margin-right: 13px;
}

@media (max-width: 660px) {
    .order__tickets-timer {
        margin-right: 5px;
    }
}

.order__tickets-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    letter-spacing: 0.7px;
}

@media (max-width: 660px) {
    .order__tickets-text {
        font-size: 12px;
    }
}

.order__tickets-time {
    color: #ea0000;
}

.order__tickets-date {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    padding: 0 70px 25px 130px;
    background-color: #e7e7e7;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-bottom: 64px;
}

@media (max-width: 730px) {
    .order__tickets-date {
        /* grid-template-columns: 240px; */
        column-gap: 0;
        row-gap: 10px;
        justify-content: center;
        max-width: 100%;
        width: 100%;
        margin: 0 auto 20px;
        padding: 0 0 25px;
        text-align: center;
    }
}

.order__date-item {
    padding-top: 23px;
}

.order__date-image {
    margin-bottom: 10px;
}

.order__date-text,
.order__date-time {
    margin: 0;
    font-weight: 500;
    color: #4c4c4c;
}

.order__date-text {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 11px;
}

.order__date-time {
    font-size: 14px;
    line-height: 14px;
}

.order__ticket-description {
    color: #4c4c4c;
    margin: 0 10px 0 94px;
    max-width: 990px;
}

@media(max-width: 1230px) {
    .order__ticket-description {
        margin: 0;
    }
}

.order__ticket-description p {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
}

.order__tickets {
    max-width: 1207px;
    margin: 0 auto;
}

.order__tickets-title {
    margin: 0 0 18px 23px;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    color: #4c4c4c;
}

@media (max-width: 1230px) {
    .order__tickets-title {
        text-align: center;
    }
}

.order__tickets-wrapper {
    display: flex;
    justify-content: space-between;
}

@media(max-width: 1230px) {
    .order__tickets-wrapper {
        flex-direction: column;
    }
}

.order__tickets-box {
    width: 45%;
    padding-left: 15px;
}

@media(max-width: 1230px) {
    .order__tickets-box {
        width: 100%;
        margin: 0 auto 70px;
        padding-left: 0;
    }
}

.order__tikets-blank {
    margin: 0 0 100px 0;
}

@media (max-width: 1230px) {
    .order__tikets-blank {
        margin-left: 0;
        margin-bottom: 50px;
    }
}

.order__blank-item {
    display: flex;
    margin-bottom: 38px;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    overflow: hidden;
    max-width: 515px;
}

@media (max-width: 1230px) {
    .order__blank-item {
        margin: 0 auto 40px;
    }
}

@media (max-width: 660px) {
    .order__blank-item {
        /* max-width: 300px; */
        width: 100%;
        margin: 0 auto 20px;
    }
}

.order__blank-item:last-child {
    margin-bottom: 0;
}

.order__blank-item.active .order__blank-number {
    background-color: #ea0000;
}

.order__blank-number {
    max-width: 80px;
    width: 100%;
    padding-top: 50px;
    font-weight: 500;
    font-size: 47px;
    line-height: 33px;
    color: #fff;
    text-align: center;
    background-color: #929292;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

@media (max-width: 660px) {
    .order__blank-number {
        max-width: 50px;
        width: 100%;
        padding-top: 40px;
        font-weight: 400;
        font-size: 35px;
        line-height: 35px;
    }
}

.order__blank-date {
    display: flex;
    flex-direction: column;
    max-width: 516px;
    width: 100%;
    margin: 0;
    padding: 24px 20px 21px 46px;
    font-weight: 500;
}

@media (max-width: 660px) {
    .order__blank-date {
        padding: 10px;
    }
}

.order__blank-name {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 20px;
    color: #4c4c4c;
}

@media (max-width: 660px) {
    .order__blank-name {
        margin-bottom: 10px;
        padding-top: 8px;
        font-size: 21px;
    }
}

.order__blank-datetime {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #798da7;
}

@media(max-width: 660px) {
    .order__blank-datetime {
        margin-bottom: 10px;
    }
}

.order__blank-price {
    font-size: 20px;
    line-height: 22px;
    color: #ea0000;
}

    .order__blank-price span {
        font-size: 23px;
    }

.order__blank-button {
    display: flex;
    flex-direction: column;
    width: 85px;
    flex: 0 0 85px;
    border-left: 2px solid #f6f6f6;
    overflow: hidden;
}

.order__blank-btn {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-weight: 500;
    font-size: 34px;
    line-height: 33px;
    color: #c6c5cb;
    text-align: center;
    border: none;
    background: transparent;
    transition: 0.3s;
    cursor: pointer;
}

    .order__blank-btn:hover,
    .order__blank-btn:focus {
        background-color: #f85a5a;
    }

@media (max-width: 660px) {
    .order__blank-btn {
        padding: 6px 11px 10px 10px;
    }
}

.order__blank-btn_plus {
    border-bottom: 1px solid #f6f6f6;
}

.order__blank-btn_minus {
    border-top: 1px solid #f6f6f6;
}

.order__gettickets-btn {
    display: block;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 17px 0 18px;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    background-color: #eb1818;
    border-radius: 50px;
    border: none;
    transition: 0.3s;
    cursor: pointer;
    box-shadow: 0px 15px 27px 0px rgba(219, 22, 35, 0.45);
}

@media (max-width: 660px) {
    .order__gettickets-btn {
        position: fixed;
        z-index: 9999999;
        bottom: 30px;
        max-width: 93%;
        left: 10px;
    }
}

@media (max-width: 650px) {
    .tickets-btn {
        position: fixed;
        z-index: 99999999;
        bottom: 0px;
        padding: 66px 0px 54px;
        width: 100%;
        background-color: white;
        left: 0px;
        right: auto;
    }
}
    .order__gettickets-btn:hover,
    .order__gettickets-btn:focus {
        background-color: #dd2504;
        transform: translate3d(0,-3px,0);
    }

.order__entertainment-box {
    display: flex;
    justify-content: flex-end;
    width: 55%;
    padding-left: 20px;
    position: relative;
    z-index: 0;
}

.order__entertainment-wrap {
    max-width: 470px;
    width: 100%;
}

@media (max-width: 1230px) {
    .order__entertainment-box {
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        padding-left: 0;
    }
}

.order__entertainment-image {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 36px;
    padding: 5px 4px;
    border: 2px solid #e3e3e3;
}

.order__entertainment-title {
    margin: 0 0 16px;
    padding-left: 7px;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    color: #4c4c4c;
}

.order__entertainment-icon {
    max-width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.order__restrictions-icon {
    max-width: 281px;
    width: 100%;
    height: auto;
    margin-bottom: 54px;
}

.order__map-img {
    max-width: 475px;
    width: 100%;
    height: auto;
    border: 2px solid #adadad;
    border-radius: 5px;
}

/*------------------------------------------------------------------------
################## ORDER PAGE END
-------------------------------------------------------------------------*/

/*------------------------------------------------------------------------
################## TOCKETS PAGE START
-------------------------------------------------------------------------*/
/* .tickets { */
/* min-height: calc(70vh);
    height: 100%;*/
/* height: calc(100vh); */
/* } */
.about {
    padding: 4%;
}

@media (min-width: 1550px) and (max-width:2500px) {
    .about {
        padding: 9%;
    }
}

@media (max-width: 756.98px) {
    .tickets {
        padding: 70px 0;
    }
}

.tickets-container {
    max-width: 1488px;
    width: 100%;
    margin: 0 auto;
}

.tickets__wrapper {
    max-width: 1380px;
    width: 100%;
    padding: 67px 54px;
    border: 1px solid #ccc;
    /* min-height: 595px; */
}

@media (max-width: 756.98px) {
    .tickets__wrapper {
        padding: 20px 15px;
        min-height: 300px;
    }
}

.tickets .nav-item {
    max-width: 350px;
    width: 100%;
}

@media (max-width: 839px) {
    .tickets .nav-item {
        max-width: 100%;
    }
}

.tickets .nav-link {
    font-size: 15px;
    line-height: 15px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0;
    background: #959595;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 10px 19px;
}

    .tickets .nav-link.active {
        background: #e31c1c;
    }

    .tickets .nav-link i {
        font-size: 15px;
        line-height: 15px;
        color: #fff;
        display: block;
        margin: 0 5px 0 0
    }

.icon-rotate {
    transform: rotate(-45deg );
}

.tickets__content {
    text-align: center;
    padding: 103px 0 0;
}

@media (max-width: 839px) {
    .tickets__content {
        text-align: center;
        padding: 50px 0 0;
    }
}

.tickets__content-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #3b404d;
    margin: 0 0 18px;
}

.tickets__content-instruction {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #3b404d;
    margin: 0;
}

.tickets__content-app {
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #3b404d;
    margin-bottom: 31px;
    letter-spacing: -0.4px;
    padding: 43px 0 0;
    /* text-decoration: underline; */
}

.tickets__social-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.tickets__social-link {
    display: inline-block;
    max-width: 120px;
    width: 100%;
}

.tickets__table-box {
    padding: 70px 0 0;
}

.tickets__table-wrapper {
    border: 1px solid #cecece;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    max-width: 666px;
    margin: 0 auto;
}

.tickets__table {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    border-collapse: collapse;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
}

.tickets__table-row:first-child {
    background-color: #cecece;
}

.tickets__table-profile {
    padding: 2px 0 2px 31px;
}

.tickets__table-profile {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
    color: #4e4e4e;
    letter-spacing: -0.5px;
}

.tickets__table-name,
.tickets__table-email,
.tickets__table-password,
.tickets__table-user {
    padding: 22px 33px;
    font-size: 16px;
    line-height: 16px;
    color: #5c5c5c;
    border-bottom: 1px solid #cecece;
    letter-spacing: -0.6px;
    vertical-align: middle;
}

.tickets__table-border_none {
    border-bottom: none;
}

.tickets__table-user {
    color: #797979;
}

.tickets__tabl_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tickets__table-space {
    display: block;
    margin-right: 40px;
    white-space: nowrap;
}

.tikets__table-icon {
    color: #797979;
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 19px;
    float: right;
    transform: translate(-7px, -3px);
}

    .tikets__table-icon:hover,
    .tikets__table-icon:focus {
        text-decoration: none;
        color: #e31c1c;
    }

.terms__content-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #3b404d;
    margin: 0 0 18px;
}

.faq__wrapper {
    background: #fff;
}

.terms__content {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #3b404d;
    margin: 0 0 15px;
}

    .terms__content:last-child {
        margin-bottom: 0;
    }

.tickets__faq-box .card-header {
    padding: 0;
    border: none
}

.tickets__faq-box .rotate-icon {
    float: right;
    margin-right: 10px;
}

.tickets__faq-box .accordion > .card {
    border: none;
    border-bottom: 1px solid #ccc;
}

.faq__header {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    margin: 0;
    color: #4c4c4c;
    display: block;
    padding: 20px 0;
}

.tickets__faq-box .card-header a:focus,
.tickets__faq-box .card-header a:hover {
    text-decoration: none;
}

.tickets__faq-box .card-header a[aria-expanded="true"] .rotate-icon {
    transition: all 0.3s ease;
    transform: rotate(180deg);
}

.tickets__faq-box .accordion > .card > .card-header {
    background: transparent;
}
/*------------------------------------------------------------------------
################## TICKETS PAGE END
-------------------------------------------------------------------------*/

/*------------------------------------------------------------------------
################## MODALS START
-------------------------------------------------------------------------*/

/*modal payments start*/
.payments .modal-dialog {
    max-width: 1260px;
}

.payments .modal-content {
    border-radius: 15px;
}

.payments__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

@media(max-width: 980px) {
    .payments__wrapper {
        flex-direction: column;
    }
}

.payments__form {
    width: 100%;
    padding: 111px 115px;
}

@media (max-width: 1235px) {
    .payments__form {
        padding: 30px 10px;
    }
}

@media (max-width: 980px) {
    .payments__form {
        width: 100%;
        margin: 0 auto 35px;
    }
}

.payments__form-title {
    color: #1a2125;
    font-size: 19px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 30px;
    letter-spacing: -0.6px;
}

.payments__form-item {
    padding: 30px 20px 0;
    border: 1px solid #c6c6c6;
    margin-bottom: 15px;
}

.payments__form-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-control-label__text {
    color: #1a2125;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    transform: translate(5px, 1px);
    display: block;
}

.payments__form-picture {
    transform: translateY(-4px);
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #0094f8;
    background-color: #0094f8;
}

.modal-form__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-checkbox {
    display: flex;
    align-items: center;
}

.custom-control-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 9px;
    color: #626161;
}

    .custom-control-label::after,
    .custom-control-label::before {
        top: -2px;
    }

.modal-form__row-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #626161;
    text-decoration: none;
}

.payments__form-describe {
    font-size: 13px;
    line-height: 26px;
    color: #353b48;
    font-weight: 500;
    margin-bottom: 15px;
    padding-left: 28px;
}

.payments__form-inputs {
    padding: 15px 0 0;
}

.payments__form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 600px) {
    .payments__form-row {
        flex-direction: column;
    }
    .payment .modal-content{
        width: auto !important;
    }
}

.input {
    font-family: 'Avenir';
    font-size: 13px;
    line-height: 13px;
    padding: 13px 20px;
    border: 1px solid #c6c6c6;
    margin-bottom: 27px;
    width: 100%;
}

.input_card {
    background: url(../images/card_icon.png) calc(100% - 15px) center no-repeat;
    padding-right: 20px;
}

.label_big {
    width: 250px;
}

@media (max-width: 600px) {
    .label_big {
        width: 100%;
    }
}

.label_small {
    width: 117px;
}

@media (max-width: 600px) {
    .label_small {
        width: 100%;
    }
}

.payments__form-label {
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #a9b1b7;
    display: block;
    margin-bottom: 8px;
}

.input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #778994;
}

.input::-moz-placeholder { /* Firefox 19+ */
    color: #778994;
}

.input:-ms-input-placeholder { /* IE 10+ */
    color: #778994;
}

.input:-moz-placeholder { /* Firefox 18- */
    color: #778994;
}

.payments__form-wrap {
    text-align: right;
    margin-bottom: 30px;
}

.payments__form-submit {
    border-radius: 20px;
    background-color: rgb(0, 119, 226);
    box-shadow: 0px 15px 27px 0px rgba(25, 132, 229, 0.45);
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    border: none;
    min-width: 190px;
    padding: 16px 5px 14px;
    &.disabled {
        background-color: #ccc; 
        box-shadow: none; 
        pointer-events: none; 
        opacity: 0.6; 
    }
}


    .payments__form-submit:hover,
    .payments__form-submit:focus {
        background-color: rgba(25, 132, 229, 0.9);
        transform: translate3d(0,-3px,0);
    }

.payments__data {
    width: 38%;
    padding: 87px 38px;
    background: #e7e7e7 url(../images/check_bg.png) center center no-repeat;
}

@media (max-width: 1235px) {
    .payments__data {
        padding: 30px 10px;
    }
}

@media (max-width: 980px) {
    .payments__data {
        padding: 50px 0;
        margin: 0 auto;
        width: 100%;
    }
}

.payments__data-wrapper {
    width: 100%;
    position: relative;
    z-index: 0;
    padding: 16px 45px 38px;
    background-color: #fff;
}

@media (max-width: 574px) {
    .payments__data-wrapper {
        width: 73%;
    }
}
@media screen and (min-width: 376px ) and (max-width: 573px) {
    .payments__data-wrapper {
        width: 94%;
    }
}
@media screen and (min-width: 320px ) and (max-width: 375px) {
    .payments__data-wrapper {
        width: 93%;
    }
}
@media (max-width: 980px) {
    .payments__data-wrapper {
        margin: 0 auto;
    }
}

@media (min-width: 320px) {
    .payments__data-wrapper::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: -40px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAW4AAAAoCAYAAADe1BBKAAACG0lEQVR4nO3du4vcVRiA4VcJFl4CKopBQ6qgKF4hEALamiakiFpoIRqwVCxErQQLsbHRKoWkDBHRKkUsQ4qAlYhNWv8DQRQCrgxMIaJhLierZ3iefr/lnG94i5nf7ty2t7fXAB9Vb44YBLCDfq5erH4dcbQR4T5QfV89s4OXDTDK89XVEbNuHzDj2erxAXMAdtlLo842ItwvV3cMmAOwy85U944437bhfqB6daevGmCMR6rXRkzaNtyLDyQfHnQogF33dnVw2zNuE+5FsN/xMgNY2dHq3W2va5twf1wdsi+Atby3fKhjY5uG+5XqDbsCWNtd1bnqnk2vbpNwP1F9sXgG3L4ANnJs2dGNrBvuxVsjF6oH7QpgK69Xn24yYJ1wP1R9Uz1pVwBDvF99su6gVcP9WHWpOm5XAEN9WH1Z3b3q0FXCfar6rnrOrgBuicXfxFyunl5l+M3CfV/1WfVtddiuAG6pE9WV6oPlkyf/6p/+O+Cdy8f9Fj/8qD0B7Lsfq8+rr6pf/v7L/xruI9Xp6mz1lD0B/OeuV18v3/n4obrRMtxvVSerF6r77Qngf+eP6qfqWnVxEe4hX4EDwL44v/hw8jd3DTCN30d8kQIA+0i4ASYj3ACTEW6AyQg3wGSEG2Aywg0wGeEGmIxwA0xGuAEmI9wAkxFugMkIN8BkhBtgMsINMBnhBpiMcANMRrgBJiPcAJMRboCZVH8CPpY792wF7iYAAAAASUVORK5CYII=);
        background-size: 100% 100%;
        width: 100%;
        height: 40px;
        display: block;
        background-repeat: no-repeat;
        left: 0;
    }

    .payments__data-wrapper::after {
        content:"";
        position: absolute;
        z-index: 0;
        margin: 0 auto;
        top: auto;
        bottom: -10px;
        left: 0;
        right: 0;
        background: url('../images/check_bottom.png');
        background-size: 100% 100%;
        width: 100%;
        height: 40px;
        display: block;
        background-repeat: no-repeat;
    }
}
@media (min-width: 320px) and (max-width: 767px){
    .right-checkout-sec{
        width: 100%;
    }
    .checkout-section{
        flex-direction: column;
    }
}
.payments__logo-wrapper {
    margin-bottom: 54px;
    text-align: center;
}

.payments__data-logo {
    position: relative;
    z-index: 0;
    width: 200px;
}

.payments__data-title {
    position: relative;
    z-index: 0;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 21px;
    line-height: 29px;
    color: #1a2125;
}

.payments__data-name {
    position: relative;
    z-index: 0;
    margin-bottom: 14px;
    font-weight: 500;
    font-size: 17px;
    color: #d8090e;
}

.payments__data-row {
    margin-bottom: 18px;
}

.payments__data-part {
    display: block;
    margin-bottom: 1px;
    font-weight: 500;
    font-size: 8px;
    color: #d8090e;
    text-transform: uppercase;
}

.payments__data-value {
    font-weight: 500;
    font-size: 15px;
    line-height: 13px;
    color: #353b48;
}

.payments__data-discount {
    display: flex;
    margin-bottom: 22px;
    padding-top: 13px;
}

.payments__discount-field {
    max-width: 277px;
    width: 100%;
    height: 34px;
    padding: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #7f8fa6;
    border: 1px solid #f0f0f0;
    border-right: none;
    background-color: #fafafa;
}

.payments__discount-butt {
    font-weight: 500;
    font-size: 12px;
    color: #ed3269;
    text-transform: uppercase;
    border: 1px solid #f0f0f0;
    background-color: #fafafa;
    border-left: none;
}

.payments__data-sub {
    margin-bottom: 28px;
    border-bottom: 1px solid #cdcfd1;
}

.payments__data-common {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 12px;
    color: #7f8fa6;
}

    .payments__data-common:last-child {
        padding-bottom: 3px;
    }

.payments__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payments__total-title {
    font-weight: 700;
    font-size: 21px;
    color: #353b48;
}

.payments__total-value {
    font-weight: 700;
    font-size: 21px;
    color: #d8090e;
}
/*modal payments end*/

/*modal confirmation start*/
.confirmation__close-btn {
    position: absolute;
    z-index: 0;
    top: -10px;
    bottom: auto;
    left: auto;
    right: -10px;
}

.confirmation .confirmation__close-btn {
    top: -19px;
    right: -22px;
    opacity: 1;
}

@media (max-width: 660px) {
    .confirmation__close-btn {
        top: 5px;
        right: 10px;
    }
}

.confirmation {
    text-align: center;
}

@media (min-width: 576px) {
    .confirmation .modal-dialog {
        max-width: 846px;
    }
}

.confirmation__modal-content {
    padding: 60px 15px;
    border: none;
    text-align: center;
}

.confirmation__icon {
    margin-bottom: 50px;
}

.confirmation__title {
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 40px;
    line-height: 92px;
    color: #4c4c4c;
}

@media (max-width: 660px) {
    .confirmation__title {
        margin-bottom: 0px;
        font-size: 35px;
    }
}

.confirmation__text {
    display: inline-block;
    position: relative;
    z-index: 0;
    margin-bottom: 13px;
    font-weight: 300;
    font-size: 17px;
    line-height: 30px;
    color: #3b404d;
}

@media (max-width: 660px) {
    .confirmation__text {
        margin-bottom: 8px;
        font-size: 12px;
        width: 300px;
        text-align: left;
        line-height: 15px;
        font-weight: bold;
        letter-spacing: 1.15px;
    }
}

.confirmation__text:nth-child(2) {
    margin-bottom: 50px;
}

.confirmation__text::before {
    content: '';
    position: absolute;
    z-index: 0;
    max-width: 8px;
    width: 100%;
    height: 7px;
    top: 11px;
    bottom: auto;
    left: -15px;
    right: auto;
    background-color: #da0000;
    border-radius: 50%;
}

@media (max-width: 660px) {
    .confirmation__text::before {
        top: 4px;
        left: -15px;
    }
}

.confirmation__title-text {
    display: inline-block;
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #3b404d;
}

@media (max-width: 660px) {
    .confirmation__title-text {
        margin-bottom: 20px;
        font-size: 10px;
        line-height: 15px;
    }
}
/*modal confirmation end*/

/* FAVORIT MODAL START */
.favorit__modal-dialog {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
}

.favorit__modal-content {
    width: 100%;
    margin: 0 auto;
    padding: 50px 10px;
    background-color: #f7f7f7;
}

.modal__close-btn {
    position: absolute;
    z-index: 1;
    top: 52px;
    bottom: auto;
    left: auto;
    right: 25px;
}

.favorit__content-wrapper {
    padding: 0 35px;
}

.favorit__content-title {
    position: relative;
    z-index: 0;
    margin-bottom: 100px;
    font-weight: 300;
    font-size: 26px;
    line-height: 33px;
    color: #4c4c4c;
}

    .favorit__content-title::after {
        content: '';
        position: absolute;
        z-index: 0;
        max-width: 49px;
        width: 100%;
        height: 4px;
        top: auto;
        bottom: -8px;
        right: auto;
        left: 0;
        background-color: #ff0000;
    }

.favorite-cart-image {
    max-width: 100%;
    height: auto;
}

.favorite__cart-wrapper {
    max-width: 307px;
    width: 100%;
    margin: 0 auto;
}

.favorite__cart-content {
    padding: 25px 20px;
    background-color: #fff;
}

.favorite__cart-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.favorite__cart-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 32px;
    color: #ff0000;
}

.favorite__cart-price {
    display: block;
    position: relative;
    z-index: 0;
    font-weight: 700;
    font-size: 17px;
    line-height: 49px;
    color: #20b2f0;
}

    .favorite__cart-price::before {
        content: 'from';
        position: absolute;
        z-index: 0;
        top: -5px;
        bottom: auto;
        left: 3px;
        right: auto;
        font-style: italic;
        font-weight: 300;
        font-size: 9px;
        line-height: 30px;
    }

.favorit__date-box {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    width: 95%;
}

.favorite__date-icon {
    margin-right: 8px;
}

.favorit__date-box span {
    display: block;
    margin-right: 3px;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
}

.favorite__date-sun {
    color: #252525;
}

.favorite__date-april {
    color: #ff3115;
}

.favorite__date-year {
    color: #787878;
}

.favorite__date-town {
    color: #20b2f0;
}
/* FAVORIT MODAL END */

/* LOGIN MODALS START */
@media (min-width: 576px) {
    .login .modal-dialog {
        max-width: 1170px;
        margin: 1.75rem auto;
    }
}

.login .modal-dialog {
    max-width: 1170px;
    margin: 1.75rem auto;
}

.login .close {
    position: absolute;
    top: 13px;
    right: 13px;
}

.login__wrapper {
    display: flex;
    align-items: stretch;
}

.login__img {
    width: 44%;
}

@media (max-width: 720px) {
    .login__img {
        display: none;
    }
}

.login__form {
    width: 56%;
}

.errorMessage {
    color: red;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    width: auto;
}

.p0 {
    padding: 0px;
}

@media (max-width: 720px) {
    .login__form {
        width: 100%;
    }
    /* .navbar-collapse{
        
        
    } */
    .qrcodeimg {
        /* height: 200px; */
        width: 100px;
    }

    .qrcodelbl {
        font-size: 10px;
    }
}

.modal-form__box {
    max-width: 400px;
    margin: 0 auto;
    padding: 90px 10px;
    color: #626161;
}

.modal-form__title {
    font-size: 30px;
    line-height: 32px;
    color: #818181;
    font-weight: 700;
    margin: 0 0 6px;
    text-align: center;
}

.modal-form__text {
    position: relative;
    z-index: 0;
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 51px;
    text-align: center;
    /* letter-spacing: -0.2px; */
}

    .modal-form__text::after {
        content: '';
        position: absolute;
        max-width: 83px;
        width: 100%;
        height: 3px;
        margin: 0 auto;
        top: 33px;
        bottom: a;
        left: 0;
        right: 0;
        background-color: #ff0d0d;
        margin-top: 20px;
    }

.signup__text {
    margin-bottom: 68px;
}

.modal-form__wrapper {
    margin-bottom: 34px;
}

.modal-form__wrapper_signup {
    margin-bottom: 5px;
}

.modal-form__field {
    margin: 0 0 15px;
    padding: 0 22px;
    height: 37px;
    font-size: 12px;
    line-height: 37px;
    color: #8b8b8b;
    border: 1px solid rgb(183, 183, 183);
    border-radius: 65px;
    width: 100%;
}

.modal-form__field_signup {
    margin-bottom: 17px;
}

.modal-form__submit_signup {
    margin-bottom: 12px;
}

.modal-form__submit {
    margin-bottom: 17px;
    background-color: rgb(207, 0, 0);
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0 10px;
    border: none;
    color: #fff;
    border-radius: 40px;
    width: 100%;
}

.modal-form__link {
    margin-bottom: 49px;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #626161;
    text-align: center;
}

    .modal-form__link a {
        color: #ce0000;
    }

.modal-form__social-title {
    margin-bottom: 28px;
    position: relative;
    z-index: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #626161;
    text-align: center;
}

    .modal-form__social-title::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: auto;
        bottom: 7px;
        left: 0;
        right: auto;
        max-width: 64px;
        width: 100%;
        height: 1px;
        background-color: #e0dfdf;
    }

    .modal-form__social-title::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: auto;
        bottom: 7px;
        left: auto;
        right: 0;
        max-width: 64px;
        width: 100%;
        height: 1px;
        background-color: #e0dfdf;
    }

.modal-form__social-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-form__social-link {
    margin-right: 40px;
}

    .modal-form__social-link:hover,
    .modal-form__social-link:focus {
        filter: grayscale(1);
    }
/* LOGIN MODALS END */

/* LGIN / SIGN UP STRART */
.login .modal-content {
    border-radius: 11px;
    overflow: hidden;
    padding: 0%;
    height: auto;
}

.dropdown__menu-modal {
    width: 344px;
    border-radius: 11px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 11px 27px;
    padding: 16px;
}

    .dropdown__menu-modal p {
        white-space: normal;
    }
    @media (max-width: 768px) {
        .dropdown__menu-modal {
            width: calc(100vw - 20px); 
            max-width: 100%; 
            margin: 0 10px;
            border-radius: 11px; 
            box-shadow:  rgba(0, 0, 0, 0.16) 0px 11px 27px;
            padding: 16px; 
        }
    }
.login__up-modal {
    margin: 0 0 27px;
    padding: 0;
}

    .login__up-modal:last-child {
        margin: 0;
    }

.dropdown-item__wrapper {
    display: flex;
}

.login__user-modal {
    margin-bottom: 23px;
    padding-bottom: 18px;
    border-bottom: 2px solid #c7c7c7;
}

.login__up-login {
    display: flex;
    margin-bottom: 5px;
}

.login__icon {
    margin-right: 14px;
}

.login__title {
    font-weight: 400;
    font-size: 1em;
    color: #8f8f8f;
    margin: 0;
}

.login__title-link {
    display: block;
}

.login__title span {
    color: #3a3a3a;
    font-weight: 500;
}

.buyer__login-arrow,
.organiser__login-arrow {
    margin-left: auto;
}

.buyer__login-arrow {
    color: #0096ff;
    font-size: 13px;
    line-height: 13px;
    margin-top: 3px;
}

.organiser__login-arrow {
    color: #ff5252;
    font-size: 13px;
    line-height: 13px;
    margin-top: 3px;
}

.login__describe {
    padding-left: 29px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: #3a3a3a;
}

    .login__describe:last-child {
        margin: 0;
    }

.header__events-btn_user {
    width: 33px;
    height: 33px;
    padding-bottom: 4px;
    border: 1px solid #c5c5c5;
    border-radius: 50%;
    background-color: #c5c5c5;
}
/* LGIN / SIGN UP END */

/* CREAT EVENTS START */
.header__events-user {
    padding: 5px 8px 10px;
    background-color: #fff;
    border: 1px solid #c6c6c6;
    border-radius: 50%;
    cursor: pointer;
}

.login__list-item {
    margin-bottom: 13px;
}

    .login__list-item:not(:first-child) {
        margin-bottom: 14px;
    }

.login__list-link {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #393939;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
}

    .login__list-link:hover,
    .login__list-link:focus {
        color: #ff5252;
        text-decoration: none;
    }

.login__list-icon {
    margin-right: 11px;
}

.modal-body {
    padding: 0 !important;
}

.m-40 {
    margin-bottom: 40px;
}

.dropdown-toggle::after {
    display: none
}

.slick-prev {
    left: 3% !important;
    z-index: 1;
}

.slick-next {
    right: 3% !important;
    z-index: 1;
}

.rangeslider-horizontal {
    height: 5px !important;
}

.rangeslider__fill {
    background-color: #ff2626 !important;
}

.rangeslider__handle {
    background-color: #ff2626 !important;
    border: 1px solid #e90101 !important;
    box-shadow: none !important;
}
.rangeslider-horizontal .rangeslider__handle:after{
    box-shadow: none !important;
}
.rangeslider__labels {
    margin-top: 5px !important;
}

.res {
    width: 80% !important;
}

.cutsom-select-hash {
    margin-right: 15px;
    width: 100%;
}

.payment .modal-dialog {
    max-width: 1260px;
    margin: 1.75rem auto;
}

.paymentSuccess .modal-dialog {
    max-width: 910px;
}

.pixter-title{
    font-weight: 400;
    color: #FF0000;
    font-size: 55px;
}
.about-main{
    padding: 90px 0;
    
}
.event-booking-portal{
    background-color: #D2D2D2;
}
.about-main header{
    line-height: 10px;
    margin-bottom: 40px;
}
.about-main p{
    font-size: 27px;
    font-weight: 500;
    color: #484848;
    font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
}
.booking-section{
    padding: 80px 0 100px 0;
}
.color-red{
    color: #FF0000;
}
.booking-sec {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}
.booking-sec img.middleImg{
    height: 522px;
    max-width: 100%;
}

.booking-sec h6{
    font-size: 17px;
    font-weight: 700;
    height: 100px;
}
.booking-sec img{
    height: 360px;
    max-width: 168px;
    width:100%;
}
.booking-sec:nth-child(3) img{
    height: auto;
}
.main-logo{
    width: 220px;
}
.download-try-section{
    background-image: url(../images/Component1.png);
    width: 100%;
    padding: 25px 0;
}
.inner-download-section header h3, h4{
    font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
    font-size: 32px;
    font-weight: 700;
}
.inner-download-section header h4{
   color:  #3C3C3C;
}
.try-it button{
    border: none;
    cursor: pointer;
    background: none;
}
.top-bottom-custom-padding{
    padding: 100px 0;
}
.inner-organizer h4{
    font-size: 40px;
    font-weight: 600;
    font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
    color: #3C3C3C;
}
.inner-organizer h5{
    font-size: 27px;
    font-weight: 700;
    font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
    color: #FF0000;
}
.inner-organizer h6{
    font-size: 22px;
    font-weight: 600;
    font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
    color: #FF0000;
}
.inner-organizer p{
    font-size: 17px;
    font-weight: 500;
    color: #3B3B3B;
}
.inner-left-padding{
    padding-left: 60px;
}

.scank-form{
    padding-top: 60px;
}
.scank-form .form-label{
    font-size: 14px;
    font-weight: 600;
    color: #000;
    padding-left: 20px;
}
.invites--form .form-label{
    color: #3C3C3C;
}
.invites--form input.form-control{
    border: 4px solid #898989 !important;
}
.invites--form .react-tel-input .flag-dropdown{
    border: 4px solid #898989 !important;
    border-right: none !important;
}
.scank-form input.form-control{
    border: none;
    border-radius: 50px;
    background-color: #F2F2F2;
    padding: 30px 40px;
    width: 100%;
}
.scank-form .react-tel-input .flag-dropdown{
    border: none;
    /* background: transparent; */
    border-radius: 50px 0 0 50px;
}
.btn-blue{
    background-color: #0195FF;
    border-radius: 50px;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 0;
    margin-top: 30px;
}
.manage-slider-section h6{
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    min-height: 130px;
}
.w150-h120 img{
    width: 150px;
    height: 120px;
}
.modal-90w {
    max-width: none !important;
    width: 90%;
}
.try-modal img{
    height: 760px;
    object-fit: cover;
}
.try-modal .modal-content{
    padding-bottom: 0 !important;
}
.take-event{
    background: url('../images/request-code.png') 100% 100% no-repeat;
    width: 100%;
    background-size: cover;
}

.take-tour-sec h4{
    font-size: 75px;
    font-weight: 700;
    color: #FF0000;
    position: relative;
}
.take-tour-sec h4:before{
    position: absolute;
    content: "";
    width: 100px;
    height: 8px;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
}
.take-tour-sec h4 span{
    color: #fff;
    padding: 0 62px;
    display: block;
}
.take-tour-sec h6{
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 60px 0px;
}
.take-tour-sec h6 span{
    color: #FF0000;
}
.inner-organizer ul {
    list-style: none;
    padding-left: 0;
}
.inner-organizer ul li{
    font-size: 17px;
    font-weight: 500;
    color: #3B3B3B;
}
.invite-modal h2{
    color: #0094f8;
}
.invite-modal .modal-content{
    padding: 40px;
}
.invite-modal .invite--code{
    font-size: 20px;
}
.invite-modal button{
    width: 100px;
    background: #FF0000;
    border: none;
    border-radius: 20px;
    color: #fff;
    padding: 5px 10px;
}
.scans-strips{
    background: url('../images/scans-strips-full.png') no-repeat;
    height: 415px;
    background-position: left;
    background-size: cover;
}
.scans-strips{
    position: relative;
}
.scans-strips::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7)
}
.scans-strips b{
    font-size: 50px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 2;

}
.components-cards{
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the card div takes full height */
}
.components-cards h4 span img{
    width: 30px;
    height: 30px;
}
.components-cards h4 {
    color: #FF0000;
    font-weight: 700;
    font-size: 34px;
}
.components-cards h4 span {
    margin-left: 10px;
    display: inline-block;
}
.components-cards .card{
    width: 300px;
    border-radius: 8px;
    background: #E6E6E6 !important;
    flex-grow: 1;
}
.components-cards .card .card-body h4 {
    font-size: 27px;
    font-weight: 700;
    color: #494949;
}
.components-cards .card .card-body span {
    margin-left: 0;
    font-weight: 500;
    font-size: 19px;
    color: #494949;
}
.components-cards .card .card-body p span {
    font-weight: 600;
}
.components-cards .card .card-body p{
    font-size: 22px;
    font-weight: 500;
}
.card-text{
    font-size: 23px;
    font-weight: 400 !important;
}
.components-cards .card .card-body h5{
    font-weight: 600;
    font-size: 22px;
    color: #494949;
}
.font-red{
    color: #FF0000 !important;
    font-weight: 700 !important;
    display: inline-block;
}
.red-border .card{
    position: relative;
}
.red-border .card::after{
    position: absolute;
    content: "";
    width: 5px;
    height: 75px;
    background-color: #FF0000;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
}
.components-cards .card .card-body img{
    width: 100px;
}
.components-cards .card .card-body{
    padding-bottom: 10px;
}
.image-left-slightUp{
    position: absolute;
    top: 50%;
    right: -50px;
    left: auto;
    transform: translateY(-50%);
}
.image-right-slight{
    position: absolute;
    bottom: 0px;
    right: -20px;
}
.bg-grey{
    background-color: #F9F9F9;
}
.manage-slider-section img{
    width: 170px;
}
.d-content{
    display: contents;
}
.selling-sec{
    background: url('../images/start-selling.jpg') no-repeat;
    background-size: cover;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selling-btn {
    background: #fff;
    border-radius: 10px;
    border: none;
    font-size: 22px;
    font-weight: 600;
    color: #7C7C7C;
    line-height: 31px;
    width: 476px;
    height: 106px;
}
.selling-btn span{
    font-size: 40px;
    font-weight: 700;
    color: #FF0000;
}
/* CREAT EVENTS END */
/*------------------------------------------------------------------------
################## MODALS END
-------------------------------------------------------------------------*/

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    width: 100%;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

input::placeholder {
    color: #aab7c4;
}

input:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

.StripeElement.PaymentRequestButton {
    height: 40px;
}

.paypal-button-number-0 {
    display: none !important;
}

.contact_modal {
    padding: 0 !important;
}

    .contact_modal .modal-dialog {
        width: 100%;
        max-width: none;
        height: 100%;
        margin: 0;
    }

.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: #e5e5e5;
    padding-bottom: 30px;
    border: 0;
    border-radius: 0;
}

@media (max-width: 1180px) {
    .modal-content {
        margin: 10px;
    }
    .confirm-modal .modal-content {
        margin: auto !important;
    }
}

.modal-content-normal {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #e5e5e5;
    padding-bottom: 30px;
    border: 0;
    border-radius: 0;
}

.contact_modal .modal-body {
    overflow-y: auto;
}

#fb-login {
    color: #fff;
    width: 23px;
    height: 0px !important;
    border: none;
}

    #fb-login img {
        width: 23px;
        height: 24px;
    }

    #fb-login:hover,
    #fb-login:focus {
        filter: grayscale(1);
    }

    #fb-login span {
        display: none !important;
    }

.rangeslider__label-item {
    font-size: 10px !important
}

.backgroundImage {
    /*position: absolute;*/
    display: block;
    width: 100%;
    height: 50px;
    background-size: cover;
    padding: 68px 15px 184px;
    text-align: center;
    z-index: 1;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.content {
    top: calc(26vh);
    position: absolute;
    left: 30%;
    right: 0;
    z-index: 9999;
    margin-left: 20px;
    margin-right: 20px;
}

.tickets-btn_scroll {
    width: 85px;
    height: 85px;
    bottom: 65px;
    right: 1px;
    border: 1px solid #fff;
    background-color: #fff;
    background-image: url(../images/arrow_red.svg);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 42px 42px;
    border-radius: 50%;
    box-shadow: 0px 37px 46px 0px rgba(255, 0, 0, 0.07),0px 42px 27px 0px rgba(0, 0, 0, 0.05);
    transition-duration: 0.2s;
    transition-delay: 0s;
}

    .tickets-btn_scroll.display-none-desktop {
        display: none;
    }

.tickets-btn_scroll_overAll {
    width: 85px;
    height: 85px;
    bottom: 65px;
    right: 1px;
    border: 1px solid #fff;
    background-color: #fff;
    background-image: url(../images/arrow_red.svg);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 42px 42px;
    border-radius: 50%;
    box-shadow: 0px 37px 46px 0px rgba(255, 0, 0, 0.07),0px 42px 27px 0px rgba(0, 0, 0, 0.05);
    transition-duration: 0.2s;
    transition-delay: 0s;
}
h4.signup-content{
    font-size: 66px;
    font-weight: 800;
    color: #FF0100;
    line-height: 70px;
    margin-bottom: 30px;
}
h4.signup-content span{
    font-size: 47px;
    font-weight: 800;
    color: #969696;
}
@media (max-width: 660px) {
    .tickets-btn_scroll {
        display: block;
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
        padding: 17px 0 18px;
        font-weight: 400;
        height: 70px;
        font-size: 24px;
        line-height: 40px;
        color: #fff;
        background-color: #eb1818;
        border-radius: 50px;
        border: none;
        transition: 0.3s;
        cursor: pointer;
        box-shadow: 0px 15px 27px 0px rgba(219, 22, 35, 0.45);
    }

    .tickets-btn_scroll_overAll {
        width: 85px;
        height: 85px;
        bottom: 65px;
        right: 1px;
        border: 1px solid #fff;
        background-color: #fff;
        background-image: url(../images/arrow_red.svg);
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: 42px 42px;
        border-radius: 50%;
        box-shadow: 0px 37px 46px 0px rgba(255, 0, 0, 0.07),0px 42px 27px 0px rgba(0, 0, 0, 0.05);
        transition-duration: 0.2s;
        transition-delay: 0s;
        /* display: none; */
    }

    .tickets-btn_scroll.display-none-mobile {
        display: none;
    }

    .tickets-btn_scroll.display-none-desktop {
        display: block;
    }

    .featured__carousel-item {
        height: 300px !important;
        border: none !important;
    }

    .featured__carousel-img {
        height: 100% !important;
        object-fit: cover;
    }
    .confirmation__close-icon{
        width: 30px !important;
    }
    .confirmation__icon {
        margin-bottom: 10px;
        width: 60px;
    }
    .pixter-title{
        font-size: 22px;
    }
    .about-main p{
        font-size: 22px;
    }
    .about-main{
        padding: 90px 0 0 0;   
    }
    .inner-left-padding{
        padding-left: 15px;
    }
    .title{
        font-size: 22px;
    }
    .booking-sec{
        flex-direction: column-reverse;
    }
    .booking-sec h6{
        font-size: 20px;
        padding-top: 20px;
    }
    .about-main hgroup{
        padding: 20px 0;
    }
    .no-show-mobile{
        display: none;
    }
    .inner-qr-section{
        text-align: center;
    }
    .inner-qr-section .justify-content-end{
        justify-content: center !important;
    }
    .download-try-section{
        position: relative;
    }
    .try-it{
        position: absolute;
        top: -50px;
        right: 0;
    }
    .try-it button img{
        width: 60px;
    }
    .inner-download-section header h3, h4{
        font-size: 16px;
    }
    .inner-organizer{
        text-align: center;
    }
    .inner-organizer h4{
        font-size: 16px;
    }
    .inner-organizer h5{
        font-size: 20px;
        padding-top: 20px;
    }
    .inner-organizer p{
        font-size: 13px;
        font-weight: 600;
        padding: 0 40px;
    }
    .inner-organizer h6{
        font-size: 16px;
    }
    .scank-form .row{
        flex-direction: column !important;
    }
    .mobile-reverse{
        display: flex;
        flex-direction: column-reverse;
    }
    .mobile-reverse.about-main{
        padding-top: 0;
    }
    .mobile-reverse.about-main video{
        width: 100%;
    }
    .mobile-reverse.about-main .pixter-title {
        font-size: 28px;
    }
    .about-main p{
        font-family: 'pixter-granularregular';
    }
    .inner-organizer ul li{
        font-size: 13px;
        font-weight: 600;
    }
    .scank-form{
        width: 100%;
        margin-bottom: 30px;
    }
    .take-tour-sec h4{
        font-size: 43px;
    }
    .take-tour-sec h6{        
        font-size: 14px;
        padding: 80px 30px 0 30px;
    }
    .take-tour-sec h6 span{
        display: block;
    }
    .take-tour-sec{
        padding: 50px 0;
    }
    .selling-btn{
        height: 70px;
        font-size: 14px;
        width: 200px;
        color: #686868;
    }
    .scans-strips{
        height: 95px;
    }
    .selling-btn span {
        font-size: 20px;
    }
    .selling-sec{
        background-size: cover;
        height: 160px;
    }
    .mobile-font{
        font-size: 11px;
    }
    .components-cards h4 span img{
        width: 16px;
        height: 16px;
    }
    .components-cards .card .card-body{
        padding: 10px;
    }
    .components-cards .card .card-body h4{
        font-size: 13px;
    }
    .components-cards .card .card-body p {
        font-size: 11px;
    }
    .components-cards .card {
        width: 105px;
    }
    .components-cards .card .card-body span{
        font-size: 9px;
    }
    .components-cards .card .card-body h5{
        font-size: 11px;
    }
    .red-border .card::after{
        width: 3px;
        height: 30px;
        right: -15px;
    }
    .components-cards .card .card-body img{
        width: 50px;
    }
    .image-left-slightUp {
        bottom: 0px;
        top: 70%;
        left: auto;
        right: -20px;
    }
    .top-bottom-custom-padding {
        padding: 30px 0;
    }
    .header__request-btn_code{
        width: 300px;
        margin: auto !important;
        height: 40px;
    }
    h4.signup-content{
        font-size: 40px !important;
        line-height: 42px;
    }
    h4.signup-content span{
        font-size: 28px !important;
        display: block;
    }
    br{
        display: none;
    }
    .show-mobile{
        display: block !important;
    }
    .scans-strips b{
        font-size: 14px;
        font-weight: 600;
    }
    .components-cards h4{
        font-size: 11px;
        font-weight: 700;
    }
    .components-cards .card .card-body{
        width: 105px;
    }
    .font-red.d-content{
        font-size: 12px !important;
    }
    .mobile-font-11{
        font-size: 11px !important;
    }
}
/* display: block;
max-width: 500px;
width: 100%;
margin: 0 auto;
padding: 17px 0 18px;
font-weight: 400;
font-size: 24px;
line-height: 30px;
color: #fff;
background-color: #eb1818;
border-radius: 50px;
border: none;
transition: 0.3s;
cursor: pointer;
box-shadow: 0px 15px 27px 0px rgba(219, 22, 35, 0.45); */

.social-icon {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    width: 34px;
    height: 34px;
    transform: translate(10%, -48%);
}

@media (max-width:900px) and (min-width: 660px) {



    .contacts__touch-tel {
        max-height: 154px;
    }
}



@media (max-width:900px) and (min-width: 660px) {
    .contacts__touch-list {
        margin-top: 10px;
        margin-left: 162px;
        justify-content: unset !important;
    }
}



@media (max-width:900px) and (min-width: 660px) {
    .emaillink {
        margin-left: 178px !important;
    }
    .pixter-title{
        font-size: 35px;
    }
    .about-main p{
        font-size: 24px;
    }
}



@media (max-width: 660px) {
    .contacts__list-link {
        font-size: 13px;
    }

    .contacts__touch-list {
        display: flex;
        justify-content: start;
        /* max-width:275px;*/
    }

    .contacts__follow-wrapper {
        margin-top: 20px;
        padding: 20px;
    }
}

.top-section {
    display: flex;
    flex-direction: column;
    align-items: end;
    position: absolute;
    top: 10%;
    left: 0px;
    width: 100%;
}

@media (min-width: 650px) and (max-width: 1440px) {
    .top-section {
        top: 2% !important;
    }
}


    @media (max-width: 660px) {
        .top-section {
            position: static;
            top: 5%;
            padding: 20px;
        }
    }

    @media (max-width: 1000px) {
        .top-section {
            position: static;
            top: 10%;
            padding: 20px;
        }
    }

.betaTesting {
    padding-bottom: 15px;
    width: 70%
}

.connectUs {
    padding-bottom: 15px;
    width: 50%
}

.emoji {
    width: 6%;
    height: 20%;
    margin-left: 6px;
    margin-top: -2px;
}

.ctaDiv {
    width: 100%;
}

.ctaleft {
    float: left;
    width: 40%;
}

.ctaright {
    float: left;
    width: 60%;
}

@media (max-width: 660px) {
    .betaTesting {
        width: 80%
    }
}

@media (max-width: 660px) {
    .connectUs {
        width: 65%
    }
}

@media (max-width: 660px) {
    .emoji {
        width: 7%
    }

    .ctaDiv {
        margin-right: 7%;
    }

    .ctaleft {
        float: left;
        width: 30%;
    }

    .ctaright {
        float: left;
        width: 70%;
    }
}
.featured__select-box #category,
.featured__select-box #city{
    width: 100% !important;
}
@media screen and (min-width: 1280px) and (max-width:1366px) {
    
.navbar .nav-item:not(:last-child) {
    margin-right: 28px;
}
}
@media screen and (min-width: 421px) and (max-width: 576px){
    
    .saved-events-modals.modal.right.show .modal-dialog{
        right: inherit !important;
    }
    .saved-events-modals.modal.right .modal-dialog{
        width: 410px;
    }
    .favorite__cart-wrapper{
        margin-left: 0 !important;
    }

    .components-cards .card {
        width: 120px;
    }
    .image-right-slight {
        bottom: 0px;
        right: 0;
    }
}
@media screen and (min-width: 400px) and (max-width: 420px){
    
    .saved-events-modals.modal.right.show .modal-dialog{
        right: inherit !important;
    }
    .saved-events-modals.modal.right .modal-dialog{
        width: 390px;
    }
    .favorite__cart-wrapper{
        margin-left: 0 !important;
    }

    .components-cards .card {
        width: 120px;
    }
    .image-right-slight {
        bottom: 0px;
        right: 0;
    }
}
@media screen and (min-width: 370px) and (max-width: 399px){
    .saved-events-modals.modal.right.show .modal-dialog{
        right: inherit !important;
    }
    .saved-events-modals.modal.right .modal-dialog{
        width: 350px;
    }
    .favorite__cart-wrapper{
        margin-left: 0 !important;
    }
    .favorit__content-wrapper {
        padding: 0 15px;
    }
    .components-cards .card {
        width: 105px;
    }
    .image-right-slight {
        bottom: 0px;
        right: 0;
    }
}
@media screen and (min-width: 340px) and (max-width: 369px){
    .saved-events-modals.modal.right.show .modal-dialog{
        right: inherit !important;
    }
    .saved-events-modals.modal.right .modal-dialog{
        width: 340px;
    }
    .favorite__cart-wrapper{
        margin-left: 0 !important;
    }
    .favorit__content-wrapper {
        padding: 0 15px;
    }
}
@media screen and (min-width: 320px) and (max-width: 339px){
    .saved-events-modals.modal.right.show .modal-dialog{
        right: inherit !important;
    }
    .saved-events-modals.modal.right .modal-dialog{
        width: 300px;
    }
    .favorite__cart-wrapper{
        margin-left: 0 !important;
    }
    .favorit__content-wrapper {
        padding: 0 15px;
    }
    .components-cards .card {
        width: 80px;
    }
}
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
@media screen and (min-width: 992px) {
    .featured__select-wrapper{
        max-height: 113px;
    }
}

/* .events_cart-list  .upcoming_card-btn {
    max-width: 180px;
    font-size: 20px;
    padding: 9px 5px;
    bottom: -86px;
} */